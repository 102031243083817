import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSave,
  faFont,
  faTint,
  faToolbox,
  faTrash,
  faUser,
  faTachometerAlt,
  faBell,
  faCogs,
  faClipboard,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSearch,
  faRunning,
  faSmog,
  faCar,
  faBaby,
  faHandPointRight,
  faHandPointLeft,
  faMale,
  faPray,
  faChild,
  faPeopleCarry,
  faStreetView,
  faShoePrints,
  faUsersCog,
  faHiking,
  faThermometerThreeQuarters,
  faArrowLeft,
  faArrowRight,
  faChair,
  faExclamationCircle,
  faExclamationTriangle,
  faEllipsisV
} from "@fortawesome/free-solid-svg-icons";

// Add all icons needed
library.add(faTint);
library.add(faSave);
library.add(faFont);
library.add(faTrash);
library.add(faToolbox);
library.add(faUser);
library.add(faTachometerAlt);
library.add(faBell);
library.add(faCogs);
library.add(faClipboard);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faSearch);
library.add(faRunning);
library.add(faSmog);
library.add(faCar);
library.add(faBaby);
library.add(faHandPointRight);
library.add(faHandPointLeft);
library.add(faMale);
library.add(faPray);
library.add(faChild);
library.add(faPeopleCarry);
library.add(faStreetView);
library.add(faShoePrints);
library.add(faUsersCog);
library.add(faHiking);
library.add(faThermometerThreeQuarters);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faChair);
library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faEllipsisV);