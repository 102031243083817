import * as React from "react";
import { toastr } from "react-redux-toastr";

export class ErrorToastr extends React.Component {
  public static getDerivedStateFromError(error: any) {
    toastr.error("Error", error.message);
    return {};
  }

  public componentDidCatch(error: any) {
    toastr.error("Error", error.message);
  }
  
  public render() {
    return this.props.children; 
  }
}