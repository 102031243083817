import { IUser } from './IUser';

export interface IRegion {
  code: string;
  name: string;
}

export interface ICountry {
  id: string;
  name: string;
  currency: string;
  region: IRegion
}

export const defaultCountry: ICountry = {
  id: '',
  name: '-',
  currency: '-',
  region: {
    code: '-',
    name: '-'
  }
};

export interface IManager {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
}

export const defaultManager: IManager = {
  id: -1,
  firstName: '-',
  lastName: '',
  email: '-',
  title: '-'
};

export interface IEmployee {
  id: number;
  employeeId: string;
  accountName: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  country: ICountry;
  lineManager: IManager;
  isEligibleForBonus: boolean;
  manuallyModified: boolean;
  reasonOfModification: string;
}

export const defaultEmployee: IEmployee = {
  id: -1,
  employeeId: '-',
  accountName: '-',
  firstName: '-',
  lastName: '-',
  email: '-',
  title: '-',
  country: defaultCountry,
  lineManager: defaultManager,
  isEligibleForBonus: false,
  manuallyModified: false,
  reasonOfModification: '-'
};

export interface IEmployeeFormFields {
  employeeId: string;
  accountName: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  country: string;
  currency: string;
  region: string;
  lineManager: IUser;
  isEligibleForBonus: boolean;
  reasonOfModification: string;
}

export const defaultEmployeeFormFields: IEmployeeFormFields = {
  employeeId: '',
  accountName: '',
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  country: '',
  currency: '',
  region: '',
  lineManager: {
    id: -1,
    empId: '',
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    managerName: '',
    managerTitle: '',
    managerEmailId:''
  },
  isEligibleForBonus: false,
  reasonOfModification: ''
};

export interface IEmployeeForm {
  employeeId: string;
  accountName: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  countryId: string;
  lineManagerId: number;
  isEligibleForBonus: boolean;
  reasonOfModification: string;
}

export const defaultEmployeeForm: IEmployeeForm ={
  employeeId: '',
  accountName: '',
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  countryId: '',
  lineManagerId: -1,
  isEligibleForBonus: false,
  reasonOfModification: ''
}
