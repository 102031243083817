import * as Msal from '@azure/msal-browser';

let msalObj;
const state = {
  launchApp: null,
  accessToken: '',
  scopes: [],
  accountId: ''
};

function handleResponse(response) {
  if (response) {
    if (response.accessToken && response.accessToken !== '') {
      state.accessToken = response.accessToken;
    }
    const currentAccounts = msalObj.getAllAccounts();
    if (currentAccounts.length === 1) {
      state.accountId = currentAccounts[0].homeAccountId;
    }
    if (state.launchApp) {
      state.launchApp();
    }
  }
}

async function acquireToken() {
  const loginRequest = {
    scopes: state.scopes
  };
  if(!msalObj) 
    return;
  
  const values = Object.values(localStorage);
  if (values.includes('interaction_in_progress')) {
    return;
  }
  
  const currentAccounts = msalObj.getAllAccounts();
  if (currentAccounts.length === 0) {
    await msalObj.loginRedirect(loginRequest);
    return;
  } 

  await getTokenRedirect(loginRequest);
}

async function getTokenRedirect(request) {
  request.account = msalObj.getAccountByHomeId(state.accountId);
  try {
    let response = await msalObj.acquireTokenSilent(request)
    if (!response.accessToken) {
      throw new Msal.InteractionRequiredAuthError();
    }
    handleResponse(response);
  }
  catch {
    msalObj.acquireTokenRedirect(request);
  }
}

function selectAccount() {
  const currentAccounts = msalObj.getAllAccounts();
  if (currentAccounts.length === 1) {
    state.accountId = currentAccounts[0].homeAccountId;
  }
}

const authentication = {
  initialize: async (config) => {
    const instance = config.instance ? config.instance : 'https://login.microsoftonline.com/tfp/';
    const authority = `${instance}${config.tenant}`;
    state.scopes = config.scopes;
    const msalConfig = {
      auth: {
        clientId: config.applicationId,
        authority: authority,
        redirectUri: config.redirectUri
      },
      cache: {
        cacheLocation: config.cacheLocation,
        storeAuthStateInCookie: config.storeAuthStateInCookie,
        claimsBasedCachingEnabled: true
      }
    };
    msalObj = await Msal.PublicClientApplication.createPublicClientApplication(msalConfig);
    try {
      var response = await msalObj.handleRedirectPromise();
      handleResponse(response);
    }
    catch(error) {
      console.log(error);
      if (error.errorMessage.indexOf('AADB2C90118') > -1) {
        try {
          msalObj.loginRedirect({ scopes: state.scopes });
        } catch (err) {
          console.log(err);
        }
      }
    }
  },
  run: (launchApp) => {
    selectAccount();
    state.launchApp = launchApp;
    if (window.parent === window) {
      acquireToken();
    }
  },
  signOut: async () => {
    const logoutRequest = {
      account: msalObj.getAccountByHomeId(state.accountId)
    };
    msalObj.logoutRedirect(logoutRequest);
  },
  getAccessToken: () => state.accessToken,
  refreshToken: (callback) => {
    const tokenRequest = {
      scopes: state.scopes,
      forceRefresh: true
    };
    const loginRequest = {
      scopes: state.scopes
    };
    tokenRequest.account = msalObj.getAccountByHomeId(state.accountId);
    return msalObj
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        if (!response.accessToken || response.accessToken === '') {
          throw new Msal.InteractionRequiredAuthError();
        } else {
          state.accessToken = response.accessToken;
          const currentAccounts = msalObj.getAllAccounts();
          if (currentAccounts.length === 1) {
            state.accountId = currentAccounts[0].homeAccountId;
          }
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        if (error instanceof Msal.InteractionRequiredAuthError) {
          loginRequest.account = msalObj.getAccountByHomeId(state.accountId);
          return msalObj.acquireTokenRedirect(loginRequest);
        }
      });
  }
};

export default authentication;
