export const actionTypes = {
  SET_CALCULATOR_LOADING_TRUE: 'SET_CALCULATOR_LOADING_TRUE',
  RESET_CALCULATOR_STATE: 'RESET_CALCULATOR_STATE',
  GET_ACHIEVEMENTS_SUCCESS: 'GET_ACHIEVEMENTS_SUCCESS',
  GET_ACHIEVEMENTS_ERROR: 'GET_ACHIEVEMENTS_ERROR',
  VERIFY_ACHIEVEMENT_SUCCESS: 'VERIFY_ACHIEVEMENT_SUCCESS',
  VERIFY_ACHIEVEMENT_ERROR: 'VERIFY_ACHIEVEMENT_ERROR',
  EDIT_ACHIEVEMENT_SUCCESS: 'EDIT_ACHIEVEMENT_SUCCESS',
  EDIT_ACHIEVEMENT_ERROR: 'EDIT_ACHIEVEMENT_ERROR',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_ERROR: 'DOWNLOAD_ERROR',
  GET_PERFORMANCE_RATINGS_SUCCESS: 'GET_PERFORMANCE_RATINGS_SUCCESS',
  GET_PERFORMANCE_RATINGS_ERROR: 'GET_PERFORMANCE_RATINGS_ERROR',
  GET_SCOPE_HIERARCHY_SUCCESS: 'GET_SCOPE_HIERARCHY_SUCCESS',
  GET_SCOPE_HIERARCHY_ERROR: 'GET_SCOPE_HIERARCHY_ERROR',
  RUN_YTD_CALCULATION_SUCCESS:'RUN_YTD_CALCULATION_SUCCESS',
  RUN_YTD_CALCULATION_ERROR:'RUN_YTD_CALCULATION_ERROR',
  GET_BONUSSCHEMES_SUCCESS : 'GET_BONUSSCHEMES_SUCCESS',
  GET_BONUSSCHEMES_ERROR: 'GET_BONUSSCHEMES_ERROR',
  GET_RISE_VALUE_SUCCESS: 'GET_RISE_VALUE_SUCCESS',
  GET_RISE_VALUE_ERROR: 'GET_RISE_VALUE_ERROR',
};
