import * as React from 'react';

type Check = (privileges: Array<string>, userPrivileges: Array<string>) => boolean;

interface AuthProps {
  children?: any; // will be hidden if authorisation is not given
  privileges: Array<string>; // privileges required to view the UI component
  userPrivileges: Array<string>; // privileges of the current logged in user
  checkMode?: 'all' | 'any'; // name of test function
  check?: Check; // custom test function
  ifNotAuthorised?: any; // component to use instead of null
  force?: boolean; // forces authorisation to pass or fail. used for testing
}

// authorisation test functions

const checks = {
  all: (privileges: Array<string>, userPrivileges: Array<string>): boolean => {
    return privileges.length === 0 ? true : privileges.every((privilege) => userPrivileges.includes(privilege));
  },
  any: (privileges: Array<string>, userPrivileges: Array<string>): boolean => {
    return privileges.length === 0 ? true : privileges.some((privilege) => userPrivileges.includes(privilege));
  }
};

export const AuthorisationComponent: React.FC<AuthProps> = (props) => {
  const { privileges, userPrivileges, checkMode = 'all', check = null, ifNotAuthorised = null, force = null } = props;
  const checkAuth = check || checks[checkMode] || checks['all'];
  const isAuthorised = force === null ? checkAuth(privileges, userPrivileges) : force;
  return isAuthorised ? <>{props.children}</> : ifNotAuthorised;
};
