import { BonusScheme, ScopeType } from './IFinancialObjective';
import { IOption } from './IOption';

export interface IFinancialFigures {
  level1Label: string[];
  level1Contribution: number;
  level2Label: string[];
  level2Contribution: number;
  revenueLabel: string[];
  revenueContribution: number;
  tradeWorkingCapitalARLabel: string[];
  tradeWorkingCapitalARContribution: number;
  tradeWorkingCapitalUBRLabel: string[];
  tradeWorkingCapitalUBRContribution: number;
  isManualOverride: boolean;
  manualOverrideOtherCommentJustification: string;
  isTradeWorkingCapitalApplicable: boolean;
}
export interface IScoreCardFinancialFigures extends IFinancialFigures {
  level1Figure: string;
  level2Figure: string;
  revenueFigure: string;
  tradeWorkingCapitalARFigure: string;
  tradeWorkingCapitalUBRFigure: string;
  level1LabelIsAmended?: boolean;
  level1FigureIsAmended?: boolean;
  level2LabelIsAmended?: boolean;
  level2FigureIsAmended?: boolean;
  revenueLabelIsAmended?: boolean;
  revenueFigureIsAmended?: boolean;
  tradeWorkingCapitalARLabelIsAmended?: boolean;
  tradeWorkingCapitalARFigureIsAmended?: boolean;
  tradeWorkingCapitalUBRLabelIsAmended?: boolean;
  tradeWorkingCapitalUBRFigureIsAmended?: boolean;
  manualOverrideComment?: IOption | null;
  canReadLevel2Figure: boolean;  
  financeDirector?: IOption | null;
}

export interface ISelectedLevels {
  subSegmentCodes: Array<string>;
  divisionCodes: Array<string>;
  vicePresCodes: Array<string>;
  subDivisionCodes: Array<string>;
  teamCodes: Array<string>;
  countryCodes: Array<string>;
  clientCodes: Array<string>;
}

export interface ITWCFigures{
  employeeId: string;
  twcubr: string;
  twcar: string;
}

export const defaultTwcFigures: ITWCFigures = {
  employeeId: '0',
  twcubr: '0',
  twcar: '0'
}

export interface IScoreCardFormFinancialFigures extends IFinancialFigures {
  level1Figure: number;
  level2Figure: number;
  revenueFigure: number;
  tradeWorkingCapitalARFigure: number;
  tradeWorkingCapitalUBRFigure: number;
  manualOverrideCommentId?: number;  
  financeDirectorId?: number;  
}

export const defaultFinancialFigures: IScoreCardFinancialFigures = {
  level1Label: [],
  level1LabelIsAmended: false,
  level1Figure: '0',
  level1FigureIsAmended: false,
  level1Contribution: 0,
  level2Label: [],
  level2LabelIsAmended: false,
  level2Figure: '0',
  level2FigureIsAmended: false,
  level2Contribution: 0,
  revenueLabel: [],
  revenueLabelIsAmended: false,
  revenueFigure: '0',
  revenueFigureIsAmended: false,
  revenueContribution: 0,
  tradeWorkingCapitalARLabel: [],
  tradeWorkingCapitalUBRLabel: [],
  tradeWorkingCapitalARLabelIsAmended: false,
  tradeWorkingCapitalUBRLabelIsAmended: false,
  tradeWorkingCapitalARFigure: '0',
  tradeWorkingCapitalUBRFigure: '0',
  tradeWorkingCapitalARFigureIsAmended: false,
  tradeWorkingCapitalUBRFigureIsAmended: false,
  tradeWorkingCapitalARContribution: 0,
  tradeWorkingCapitalUBRContribution: 0,
  isManualOverride: false,
  canReadLevel2Figure: true,
  manualOverrideOtherCommentJustification: '',
  isTradeWorkingCapitalApplicable:false
};

export interface IBudgetaryInfo extends ISelectedLevels {
  scopeType: ScopeType;
  bonusScheme: number;
}
