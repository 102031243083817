import _ from 'lodash';

export interface ILevelMapping {
  name: string;
  code: string;
}

export interface IDivision extends ILevelMapping {
  subSegmentCodes: Array<string>;
}

export interface IVicePres extends IDivision {
  divisionCodes: Array<string>;
}

export interface ISubDivision extends IVicePres {
  vicePresCodes: Array<string>;
}

export interface ITeam extends ISubDivision {
  subDivisionCodes: Array<string>;
}

export interface IClient extends ITeam {
  teamCodes: Array<string>;
}

export interface IScopeNodeName {
  code: string;
  name: string;
  level: ScopeLevel;
}

export interface IScopePath {
  subSegment: string;
  division?: string;
  vicePres?: string;
  subDivision?: string;
  team?: string;
  country?: string;
  client?: string;
}

export const getPathLevel = (name: string): string => {
  switch (name.toLowerCase()) {
    case 'subsegments':
      return 'subSegment';
    case 'divisions':
      return 'division';
    case 'vicePres':
      return 'vicePres';
    case 'subdivisions':
      return 'subDivision';
    case 'teams':
      return 'team';
    case 'countries':
      return 'country';
    case 'clients':
      return 'client';
    default:
      return '';
  }
};

export enum ScopeLevel {
  SubSegment = 0,
  Division = 1,
  VicePres = 2,
  SubDivision = 3,
  Team = 4,
  Country = 5,
  Client = 6
}

export const getScopeLevel = (name: string): ScopeLevel | undefined => {
  switch (name.toLowerCase()) {
    case 'subsegment':
      return ScopeLevel.SubSegment;
    case 'division':
      return ScopeLevel.Division;
    case 'vicepres':
      return ScopeLevel.VicePres;
    case 'subdivision':
      return ScopeLevel.SubDivision;
    case 'team':
      return ScopeLevel.Team;
    case 'country':
      return ScopeLevel.Country;
    case 'client':
      return ScopeLevel.Client;
  }
};

export class ScopePathNodes {
  subSegments: string[] = [];
  divisions: string[] = [];
  vicePres: string[] = [];
  subDivisions: string[] = [];
  teams: string[] = [];
  countries: string[] = [];
  clients: string[] = [];

  constructor(init?: Partial<ScopePathNodes>) {
    Object.assign(this, init);
  }

  getByFieldName(field: string): string[] {
    if (field === 'clients') return this.clients;
    if (field === 'countries') return this.countries;
    if (field === 'teams') return this.teams;
    if (field === 'subDivisions') return this.subDivisions;
    if (field === 'vicePres') return this.vicePres;
    if (field === 'divisions') return this.divisions;
    if (field === 'subSegments') return this.subSegments;
    throw new Error('no field exists with given name');
  }

  getLowestLevel(): ScopeLevel {
    if (this.clients?.length > 0) return ScopeLevel.Client;
    if (this.countries?.length > 0) return ScopeLevel.Country;
    if (this.teams?.length > 0) return ScopeLevel.Team;
    if (this.subDivisions?.length > 0) return ScopeLevel.SubDivision;
    if (this.vicePres?.length > 0) return ScopeLevel.VicePres;
    if (this.divisions?.length > 0) return ScopeLevel.Division;
    return ScopeLevel.SubSegment;
  }

  areEmpty(): boolean {
    return (
      !this.subSegments?.length &&
      !this.divisions?.length &&
      !this.vicePres?.length &&
      !this.subDivisions?.length &&
      !this.teams?.length &&
      !this.countries?.length &&
      !this.clients?.length
    );
  }

  isValidWithoutVicePres(): boolean {
    if (this.clients?.length > 0) {
      return (
        this.countries?.length > 0 &&
        this.teams.length > 0 &&
        this.subDivisions?.length > 0 &&
        this.divisions?.length > 0 &&
        this.subSegments?.length > 0
      );
    }

    if (this.countries?.length > 0) {
      return (
        this.teams.length > 0 &&
        this.subDivisions?.length > 0 &&
        this.divisions?.length > 0 &&
        this.subSegments?.length > 0
      );
    }

    if (this.teams?.length > 0) {
      return this.subDivisions?.length > 0 && this.divisions?.length > 0 && this.subSegments?.length > 0;
    }

    if (this.subDivisions?.length > 0) {
      return this.divisions?.length > 0 && this.subSegments?.length > 0;
    }

    if (this.divisions?.length > 0) {
      return this.subSegments?.length > 0;
    }

    return this.subSegments?.length > 0;
  }

  isValid(): boolean {
    if (this.clients?.length > 0) {
      return (
        this.countries?.length > 0 &&
        this.teams.length > 0 &&
        this.subDivisions?.length > 0 &&
        this.vicePres?.length > 0 &&
        this.divisions?.length > 0 &&
        this.subSegments?.length > 0
      );
    }
    if (this.countries?.length > 0) {
      return (
        this.teams.length > 0 &&
        this.subDivisions?.length > 0 &&
        this.vicePres?.length > 0 &&
        this.divisions?.length > 0 &&
        this.subSegments?.length > 0
      );
    }
    if (this.teams?.length > 0) {
      return (
        this.subDivisions?.length > 0 &&
        this.vicePres?.length > 0 &&
        this.divisions?.length > 0 &&
        this.subSegments?.length > 0
      );
    }
    if (this.subDivisions?.length > 0) {
      return (
        this.vicePres?.length > 0 &&
        this.divisions?.length > 0 &&
        this.subSegments?.length > 0
      );
    }
    if (this.vicePres?.length > 0) {
      return (
        this.divisions?.length > 0 &&
        this.subSegments?.length > 0
      );
    }
    if (this.divisions?.length > 0) {
      return this.subSegments?.length > 0;
    }
    return this.subSegments?.length > 0;
  }

  static createFromPaths(paths: IScopePath[]) {
    return new ScopePathNodes({
      subSegments: _.uniq(paths.map((x) => x.subSegment).filter((x) => !!x)) as string[],
      divisions: _.uniq(paths.map((x) => x.division).filter((x) => !!x)) as string[],
      vicePres: _.uniq(paths.map((x) => x.vicePres).filter((x) => !!x)) as string[],
      subDivisions: _.uniq(paths.map((x) => x.subDivision).filter((x) => !!x)) as string[],
      teams: _.uniq(paths.map((x) => x.team).filter((x) => !!x)) as string[],
      countries: _.uniq(paths.map((x) => x.country).filter((x) => !!x)) as string[],
      clients: _.uniq(paths.map((x) => x.client).filter((x) => !!x)) as string[]
    });
  }

  static mergeNodes = (n1: ScopePathNodes, n2: ScopePathNodes): ScopePathNodes => {
    return new ScopePathNodes({
      subSegments: _.uniq([...n1.subSegments, ...n2.subSegments].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      divisions: _.uniq([...n1.divisions, ...n2.divisions].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      vicePres: _.uniq([...n1.vicePres, ...n2.vicePres].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      subDivisions: _.uniq([...n1.subDivisions, ...n2.subDivisions].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      teams: _.uniq([...n1.teams, ...n2.teams].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      countries: _.uniq([...n1.countries, ...n2.countries].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      clients: _.uniq([...n1.clients, ...n2.clients].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      })
    });
  };
}

const sortLevels = (level1: any, level2: any) => {
  const a = level1?.toLowerCase();
  const b = level2?.toLowerCase();
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
