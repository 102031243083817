import { createSelector } from 'reselect';
import { AppState } from 'state/app-state';

const getCommon = (state: AppState) => state.common;

export const getUserProfileState = createSelector(getCommon, (common) => common.userProfile);

export const getAllPrivileges = createSelector(getCommon, (common) => common.allPrivileges);

export const getAllFinancialYears = createSelector(getCommon, (common) => common.allFinancialYears);

export const getSelectedFinancialYear = createSelector(getCommon, (common) => common.selectedFinancialYear);

export const getUserPrivileges = createSelector(
  getCommon,
  (common) => (common.userProfile && common.userProfile.privileges) ?? []
);
