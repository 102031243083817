export const actionTypes = {
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_ERROR: 'GET_USER_PROFILE_ERROR',
  GET_PRIVILEGES_SUCCESS: 'GET_PRIVILEGES_SUCCESS',
  GET_PRIVILEGES_ERROR: 'GET_PRIVILEGES_ERROR',
  GET_USER_PRIVILEGES_SUCCESS: 'GET_USER_PRIVILEGES_SUCCESS',
  GET_USER_PRIVILEGES_ERROR: 'GET_USER_PRIVILEGES_ERROR',
  SET_FINANCIAL_YEAR: 'SET_FINANCIAL_YEAR',
  GET_FINANCIAL_YEARS_SUCCESS: 'GET_FINANCIAL_YEARS_SUCCESS',
  GET_FINANCIAL_YEARS_ERROR: 'GET_FINANCIAL_YEARS_ERROR'
};
