import { EventType } from 'models/EventType';
import { IFMCHierarchy, IFMCHierarchyNodeName } from 'models/FMCHierarchyLevelMapping';
import { IFMCHierarchyLevelFormFields, defaultUserScopeFMCHierarchyFormFields } from 'models/IFMCHierarchyLevelFormFields';
import { IScopeLevelFormFields } from 'models/IScopLevelFormFields';
import { IUser } from 'models/IUser';
import { IScopeNodeName, IScopePath, ScopePathNodes } from 'models/LevelMapping';
import { Notify } from 'models/Notify';
import { defaultUserScopeFormFields, defaultUserScopeHierarchy, UserScopeHierarchy } from 'models/user';

export interface ManageReportState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  searchedUsers: Array<IUser>;
  userScopeHierarchy: UserScopeHierarchy;
  userScopeInitialState: IScopeLevelFormFields;
  availableScopePaths: IScopePath[];
  selectedScopePathNodes: ScopePathNodes;
  scopeNodeNames: IScopeNodeName[];
  userScopeFMCHierarchyInitialState: IFMCHierarchyLevelFormFields;
  availableFMCHierarchy: IFMCHierarchy[];
  fmcHierarchyNodeNames: IFMCHierarchyNodeName[];
}

export const defaultManageReportState: ManageReportState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  searchedUsers: [],
  userScopeHierarchy: defaultUserScopeHierarchy,
  userScopeInitialState: defaultUserScopeFormFields,
  selectedScopePathNodes: new ScopePathNodes(),
  availableScopePaths: [],
  scopeNodeNames: [],
  userScopeFMCHierarchyInitialState: defaultUserScopeFMCHierarchyFormFields,
  availableFMCHierarchy: [],
  fmcHierarchyNodeNames: [],
};
