import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { defaultCalculatorState, CalculatorState } from './state';
import { IAchievement } from 'models/IAchievement';
import { getScopeLevel, IScopeNodeName, ScopePathNodes } from 'models/LevelMapping';
import { IScopeHierarchyResponse } from 'sections/financialObjective/models.client';

const formatAchievements = (achievements: Array<IAchievement>, id: number) => {
  const achievement = achievements.map((achievement: IAchievement) =>
    achievement.scoreCardId == id ? { ...achievement, verified: true } : achievement
  );
  return achievement;
};

const updateAchievement = (achievements: Array<IAchievement>, editedAchievement: IAchievement) => {
  const updatedAchievements = achievements.map((achievement: IAchievement) =>
    achievement.scoreCardId == editedAchievement.scoreCardId ? editedAchievement : achievement
  );
  return updatedAchievements;
};

export const handlers = {
  [actionTypes.SET_CALCULATOR_LOADING_TRUE]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_CALCULATOR_STATE]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none
    };
  },
  [actionTypes.GET_ACHIEVEMENTS_SUCCESS]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      tmData: payload.tmData,
      achievements: payload.achievements,
      offset: payload.offset,
      limit: payload.limit,
      totalNoOfRecords: payload.totalNoOfRecords,
      financialYear: payload.financialYear,
      yearToDateCalculationMonth:payload.yearToDateCalculationMonth,
      scope: new ScopePathNodes({...payload.scope})
    };
  },
  [actionTypes.GET_ACHIEVEMENTS_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      achievements: payload.achievements,
      offset: payload.offset,
      limit: payload.limit,
      totalNoOfRecords: payload.totalNoOfRecords,
      financialYear: payload.financialYear
    };
  },
  [actionTypes.VERIFY_ACHIEVEMENT_SUCCESS]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      achievements: formatAchievements(state.achievements, payload.response)
    };
  },
  [actionTypes.VERIFY_ACHIEVEMENT_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.EDIT_ACHIEVEMENT_SUCCESS]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      achievements: updateAchievement(state.achievements, payload.response)
    };
  },
  [actionTypes.EDIT_ACHIEVEMENT_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.DOWNLOAD_SUCCESS]: (state: CalculatorState, payload: any): CalculatorState => {
  return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none
    };
  },
  [actionTypes.DOWNLOAD_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.GET_PERFORMANCE_RATINGS_SUCCESS]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.getData,
      performanceRatings: payload
    };
  },
  [actionTypes.GET_PERFORMANCE_RATINGS_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.getData,
      performanceRatings: []
    };
  },
  [actionTypes.GET_RISE_VALUE_SUCCESS]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.getData,
      riseValueData: payload
    };
  },
  [actionTypes.GET_RISE_VALUE_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.getData,
      riseValueData: []
    };
  },
  [actionTypes.GET_SCOPE_HIERARCHY_SUCCESS]: (state: CalculatorState, payload: IScopeHierarchyResponse): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      scopeNodeNames: payload.codeNames.map(c => ({ code: c.code, name: c.name, level: getScopeLevel(c.level) } as IScopeNodeName)),
    };
  },
  [actionTypes.GET_SCOPE_HIERARCHY_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      scopeNodeNames: [],
    };
  },
  [actionTypes.RUN_YTD_CALCULATION_SUCCESS]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
        ...state,
        loading: false,
        success: true,
        event: payload.event,
        notify: Notify.none,
        tmData: payload.tmData,
        achievements: payload.achievements,
        offset: payload.offset,
        limit: payload.limit,
        totalNoOfRecords: payload.totalNoOfRecords,
        financialYear: payload.financialYear,
        yearToDateCalculationMonth:payload.yearToDateCalculationMonth,
        scope: new ScopePathNodes({...payload.scope})
      };
    },
  [actionTypes.RUN_YTD_CALCULATION_ERROR]: (state: CalculatorState, payload: any): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.GET_BONUSSCHEMES_SUCCESS]: (
    state: CalculatorState,
    payload: any
  ): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      bonusSchemes: payload
    };
  },
  [actionTypes.GET_BONUSSCHEMES_ERROR]: (
    state: CalculatorState,
    payload: any
  ): CalculatorState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
};

const CalculatorReducer = (state: CalculatorState = { ...defaultCalculatorState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default CalculatorReducer;
