import React, { useEffect } from "react";
import authentication from "helpers/react-msal";
import { mouseEvent, timerCounter, touchEvent } from "./consts";

export const LogoutTimer: React.FC = () => {
  let timer: any;

  const handleEvent = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      authentication.signOut();
    }, timerCounter);
  }
  useEffect(() => {
    window.addEventListener(mouseEvent, handleEvent);
    window.addEventListener(touchEvent, handleEvent);
    return () => {
      window.removeEventListener(mouseEvent, handleEvent);
      window.removeEventListener(touchEvent, handleEvent);
    }
  }, []);
  return null;
}
