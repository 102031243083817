import { EventType } from 'models/EventType';
import { Notify } from 'models/Notify';

export interface HomeState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
}

export const defaultHomeState: HomeState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none
};
