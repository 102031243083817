import { RolesState, defaultRolesState, defaultSelectedRole } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { actionTypes as financialObjectivesActionTypes } from '../../financialObjective/redux/actions';
import { IRoleUser } from 'models/IRole';

export const handlers = {
  [actionTypes.SET_ROLES_LOADING_TRUE]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_ROLES_STATE]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none
    };
  },
  [actionTypes.GET_ALL_ROLES_SUCCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      roles: payload
    };
  },
  [actionTypes.GET_ALL_ROLES_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      roles: payload.response
    };
  },
  [actionTypes.SET_NEW_ROLE_FORM]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      selectedRole: defaultSelectedRole,
      users: [],
      privileges: []
    };
  },
  [actionTypes.GET_SELECTED_ROLE_SUCCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      selectedRole: payload,
      users: payload.users,
      privileges: payload.privileges
    };
  },
  [actionTypes.GET_SELECTED_ROLE_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      selectedRole: payload.response
    };
  },
  [actionTypes.SET_SELECTED_ROLE_USERS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      users: payload
    };
  },
  [actionTypes.SET_SELECETD_ROLE_PRIVILEGES]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      privileges: payload
    };
  },
  [actionTypes.ADD_ROLE_SUCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event
    };
  },
  [actionTypes.ADD_ROLE_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.EDIT_ROLE_SUCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event
    };
  },
  [actionTypes.EDIT_ROLE_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.ADD_PRIVILEGE_TO_ROLE_SUCCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      privileges: [...state.privileges, payload.response]
    };
  },
  [actionTypes.ADD_PRIVILEGE_TO_ROLE_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.REMOVE_PRIVILEGE_FROM_ROLE_SUCCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      privileges: [...state.privileges.filter((p: string) => p !== payload.response)]
    };
  },
  [actionTypes.REMOVE_PRIVILEGE_FROM_ROLE_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.ADD_USER_TO_ROLE_SUCCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      users: [...state.users, payload.response]
    };
  },
  [actionTypes.ADD_USER_TO_ROLE_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.REMOVE_USER_FROM_ROLE_SUCCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      users: [...state.users.filter((user: IRoleUser) => user.id !== payload.response)]
    };
  },
  [actionTypes.REMOVE_USER_FROM_ROLE_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_SUCCESS]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.userList,
      searchedUsers: payload
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_ERROR]: (state: RolesState, payload: any): RolesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.userList,
      searchedUsers: payload.response
    };
  }
};

const RolesReducer = (state: RolesState = { ...defaultRolesState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default RolesReducer;
