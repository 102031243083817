import { IScopeLevelFormFields } from './IScopLevelFormFields';

export interface User {
  id: number;
  privileges: Array<string>;
}

export const defaultUserProfile: User = {
  id: -1,
  privileges: []
};

export interface UserPrivileges {
  contains: (privilege: string) => boolean;
  containsAny: (privileges: Array<string>) => boolean;
}

export const userPrivileges = (user: User): UserPrivileges => ({
  contains: (privilege: string): boolean => user.privileges.includes(privilege),
  containsAny: (privileges: Array<string>): boolean => user.privileges.some((p: string) => privileges.includes(p))
});

export interface UserScopeHierarchy {
  subSegment: string | undefined;
  divisions: string[];
  subDivisions: string[];
  teams: string[];
  countries: string[];
  clients: string[];
}

export const defaultUserScopeHierarchy: UserScopeHierarchy = {
  subSegment: undefined,
  divisions: [],
  subDivisions: [],
  teams: [],
  countries: [],
  clients: []
};

export const defaultUserScopeFormFields: IScopeLevelFormFields = {
  subSegment: [],
  divisions: [],
  vicePres: [],
  subDivisions: [],
  teams: [],
  countries: [],
  clients: []
};

export interface UserScopeFMCHierarchy {
  businessSegment: string | undefined;
  globalRegion: string[];
  region: string[];
  country: string[];
  managingOffice: string[];
  client: string[];
  location: string[];
  lineOfBusiness: string[];
  functionalUnit: string[];
}
export const defaultUserScopeFMCHierarchy: UserScopeFMCHierarchy = {
  businessSegment: undefined,
  globalRegion: [],
  region: [],
  country: [],
  managingOffice: [],
  client: [],
  location: [],
  lineOfBusiness: [],
  functionalUnit: []
};
