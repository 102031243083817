import _ from 'lodash';

export interface IFMCHierarchyLevelMapping {
  name: string;
  code: string;
}

export interface IGlobalRegion extends IFMCHierarchyLevelMapping {
  businessSegmentCodes: Array<string>;
}

export interface IRegion extends IGlobalRegion {
  globalRegionCodes: Array<string>;
}

export interface ICountry extends IRegion {
  regionCodes: Array<string>;
}
export interface IManagingOffice extends ICountry {
    countryCodes: Array<string>;
}
export interface IClient extends IManagingOffice {
  managingOfficeCodes: Array<string>;
}
export interface ILocation extends IClient {
  clientCodes: Array<string>;
}
export interface ILineOfBusiness extends ILocation {
  locationCodes: Array<string>;
}
export interface FunctionalUnit extends ILineOfBusiness {
  lineOfBusinessCodes: Array<string>;
}

export interface IFMCHierarchyNodeName {
  code: string;
  name: string;
  level: FMCHierarchyLevel;
}

export interface IFMCHierarchy {
  businessSegment: string;
  globalRegion?: string;
  region?: string;
  country?: string;
  managingOffice: string;
  client?: string;
  location?: string;
  lineOfBusiness?: string;
  functionalUnit?: string;
}
export enum FMCHierarchyLevel {
  BusinessSegment = 0,
  GlobalRegion = 1,
  Region = 2,
  Country = 3,
  ManagingOffice = 4,
  Client = 5,
  Location = 6,
  LineOfBusiness = 7,
  FunctionalUnit = 8
}


export const getFMCHierarchyLevel = (name: string): FMCHierarchyLevel=> {
  switch (name.toLowerCase()) {
    case 'businesssegment':
      return FMCHierarchyLevel.BusinessSegment;
    case 'globalregion':
      return FMCHierarchyLevel.GlobalRegion;
    case 'region':
      return FMCHierarchyLevel.Region;
    case 'country':
      return FMCHierarchyLevel.Country;
    case 'managingoffice':
      return FMCHierarchyLevel.ManagingOffice;
    case 'client':
      return FMCHierarchyLevel.Client;
    case 'location':
      return FMCHierarchyLevel.Location;
    case 'lineofbusiness':
      return FMCHierarchyLevel.LineOfBusiness;
    case 'functionalunit':
      return FMCHierarchyLevel.FunctionalUnit;    
  }
  return FMCHierarchyLevel.BusinessSegment
};
export class FMCHierarchyNodes {
  businessSegment: string[] = [];
  globalRegion: string[] = [];
  region: string[] = [];
  country: string[] = [];
  managingOffice: string[] = [];
  client: string[] = [];
  location: string[] = [];
  lineOfBusiness: string[] = [];
  functionalUnit: string[] = [];

  constructor(init?: Partial<FMCHierarchyNodes>) {
    Object.assign(this, init);
  }

  getByFieldName(field: string): string[] {  

    if (field === 'functionalUnit') return this.functionalUnit;
    if (field === 'lineOfBusiness') return this.lineOfBusiness;
    if (field === 'location') return this.location;
    if (field === 'client') return this.client;
    if (field === 'managingOffice') return this.managingOffice;
    if (field === 'country') return this.country;
    if (field === 'region') return this.region;
    if (field === 'globalRegion') return this.globalRegion;
    if (field === 'businessSegment') return this.businessSegment;
    throw new Error('no field exists with given name');
  }

  getLowestLevel(): FMCHierarchyLevel {
    if (this.functionalUnit?.length > 0) return FMCHierarchyLevel.FunctionalUnit;
    if (this.lineOfBusiness?.length > 0) return FMCHierarchyLevel.LineOfBusiness;
    if (this.location?.length > 0) return FMCHierarchyLevel.Location;
    if (this.client?.length > 0) return FMCHierarchyLevel.Client;
    if (this.managingOffice?.length > 0) return FMCHierarchyLevel.ManagingOffice;
    if (this.country?.length > 0) return FMCHierarchyLevel.Country;
    if (this.region?.length > 0) return FMCHierarchyLevel.Region;
    if (this.globalRegion?.length > 0) return FMCHierarchyLevel.GlobalRegion;
    return FMCHierarchyLevel.BusinessSegment;
  }

  areEmpty(): boolean {
    return (
      !this.businessSegment?.length &&
      !this.globalRegion?.length &&
      !this.region?.length &&
      !this.country?.length &&
      !this.managingOffice?.length &&
      !this.client?.length &&
      !this.location?.length &&
      !this.lineOfBusiness?.length &&
      !this.functionalUnit?.length
    );
  }

  isValid(): boolean {
    if (this.functionalUnit?.length > 0) {
      return (
        this.lineOfBusiness?.length > 0 && this.location.length > 0 && this.client?.length > 0 &&
        this.managingOffice?.length > 0 && this.country?.length > 0 && this.region?.length > 0 &&
        this.globalRegion?.length > 0 && this.businessSegment?.length > 0
      );
    }

    if (this.lineOfBusiness?.length > 0) {
      return (
        this.location.length > 0 && this.client?.length > 0 &&
        this.managingOffice?.length > 0 && this.country?.length > 0 && this.region?.length > 0 &&
        this.globalRegion?.length > 0 && this.businessSegment?.length > 0
      );
    }

    if (this.location?.length > 0) {
      return this.client?.length > 0 &&
        this.managingOffice?.length > 0 && this.country?.length > 0 && this.region?.length > 0 &&
        this.globalRegion?.length > 0 && this.businessSegment?.length > 0
    }

    if (this.client?.length > 0) {
      return this.managingOffice?.length > 0 && this.country?.length > 0 && this.region?.length > 0 &&
        this.globalRegion?.length > 0 && this.businessSegment?.length > 0
    }

    if (this.managingOffice?.length > 0) {
      return this.country?.length > 0 && this.region?.length > 0 &&
        this.globalRegion?.length > 0 && this.businessSegment?.length > 0
    }
    if (this.country?.length > 0) {
      return this.region?.length > 0 &&
        this.globalRegion?.length > 0 && this.businessSegment?.length > 0
    }
    if (this.region?.length > 0) {
      return this.globalRegion?.length > 0 && this.businessSegment?.length > 0
    }
    if (this.globalRegion?.length > 0) {
      return this.businessSegment?.length > 0
    }

    return this.businessSegment?.length > 0;
  }

  static createFromPaths(paths: IFMCHierarchy[]) {    
    return new FMCHierarchyNodes({

      businessSegment: _.uniq(paths.map((x) => x.businessSegment).filter((x) => !!x)),
      globalRegion: _.uniq(paths.map((x) => x.globalRegion).filter((x) => !!x)) as string[],
      region: _.uniq(paths.map((x) => x.region).filter((x) => !!x)) as string[],
      country: _.uniq(paths.map((x) => x.country).filter((x) => !!x)) as string[],
      managingOffice: _.uniq(paths.map((x) => x.managingOffice).filter((x) => !!x)) as string[],
      client: _.uniq(paths.map((x) => x.client).filter((x) => !!x)) as string[],
      location: _.uniq(paths.map((x) => x.location).filter((x) => !!x)) as string[],
      lineOfBusiness: _.uniq(paths.map((x) => x.lineOfBusiness).filter((x) => !!x)) as string[],
      functionalUnit: _.uniq(paths.map((x) => x.functionalUnit).filter((x) => !!x)) as string[]

    });
  }

  static mergeNodes = (n1: FMCHierarchyNodes, n2: FMCHierarchyNodes): FMCHierarchyNodes => {
    return new FMCHierarchyNodes({

      businessSegment: _.uniq([...n1.businessSegment, ...n2.businessSegment].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      globalRegion: _.uniq([...n1.globalRegion, ...n2.globalRegion].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      region: _.uniq([...n1.region, ...n2.region].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      country: _.uniq([...n1.country, ...n2.country].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      managingOffice: _.uniq([...n1.managingOffice, ...n2.managingOffice].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      client: _.uniq([...n1.client, ...n2.client].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      location: _.uniq([...n1.location, ...n2.location].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      lineOfBusiness: _.uniq([...n1.lineOfBusiness, ...n2.lineOfBusiness].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
      functionalUnit: _.uniq([...n1.functionalUnit, ...n2.functionalUnit].filter((x) => !!x)).sort((a, b) => {
        return sortLevels(a, b);
      }),
    });
  };
}
const sortLevels = (level1: any, level2: any) => {
  const a = level1?.toLowerCase();
  const b = level2?.toLowerCase();
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
