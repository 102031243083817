import { EventType } from 'models/EventType';
import { IFinancialObjective } from 'models/IFinancialObjective';
import { IOption } from 'models/IOption';
import { ISearchFilter } from 'models/ISearchFilter';
import { IClient, IDivision, ILevelMapping, ISubDivision, ITeam } from 'models/LevelMapping';
import { Notify } from 'models/Notify';
import { ISortDropDownItem } from '../components/formsListSorting';
import { IBonusScheme } from 'models/IFinancialObjective';
import { IFMCHierarchyLevelMapping } from 'models/FMCHierarchyLevelMapping';

export interface FormsSearchState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  listOffset: number;
  listLimit: number;
  numberOfItems: number;
  sort: ISortDropDownItem;
  formsList: Array<IFinancialObjective>;
  subSegments: Array<ILevelMapping>;
  divisions: Array<ILevelMapping>;
  vicePres: Array<ILevelMapping>;
  subDivisions: Array<ILevelMapping>;
  teams: Array<ILevelMapping>;
  businessSegments: Array<IFMCHierarchyLevelMapping>;
  globalRegions: Array<IFMCHierarchyLevelMapping>;
  regions: Array<IFMCHierarchyLevelMapping>;
  managingOffices: Array<IFMCHierarchyLevelMapping>;
  clients: Array<ILevelMapping>;
  countries: Array<any>;
  locations: Array<IFMCHierarchyLevelMapping>;
  lineOfBusinesses: Array<IFMCHierarchyLevelMapping>;
  functionalUnits: Array<IFMCHierarchyLevelMapping>;
  multiJurisdictionComments: Array<IOption>;
  appliedFilters: Array<ISearchFilter>;
  isScoreCardEditableForSelectedYear: boolean;
  bonusSchemes: Array<IBonusScheme>;
}

export const defaultFormsSearchState: FormsSearchState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  numberOfItems: 0,
  listOffset: 0,
  listLimit: 5,
  sort: {
    sortKey: 'surname_asc',
    sortField: 'lastName',
    sortDirection: 'asc',
    sortLabel: 'Sort by Employee Surname Ascending'
  },
  formsList: [],
  subSegments: [],
  divisions: [],
  vicePres:[],
  subDivisions: [],
  teams: [],
  businessSegments: [],
  globalRegions: [],
  regions: [],
  managingOffices: [],
  clients: [],
  countries: [],
  locations: [],
  lineOfBusinesses: [],
  functionalUnits: [],
  multiJurisdictionComments: [],
  appliedFilters: [],
  isScoreCardEditableForSelectedYear: false,
  bonusSchemes: []
};
