export const actionTypes = {
  SET_PREVIEW_FO_LOADING_TRUE: 'SET_PREVIEW_FO_LOADING_TRUE',
  RESET_PREVIEW_FO_STATE: 'RESET_PREVIEW_FO_STATE',
  GET_PREVIEW_FO_SUCCESS: 'GET_PREVIEW_FO_SUCCESS',
  GET_PREVIEW_FO_ERROR: 'GET_PREVIEW_FO_ERROR',
  POST_APPROVAL_SUCCESS: 'POST_APPROVAL_SUCCESS',
  POST_APPROVAL_ERROR: 'POST_APPROVAL_ERROR',
  GET_SCORECARD_EVENTS_SUCCESS: 'GET_SCORECARD_EVENTS_SUCCESS',
  GET_SCORECARD_EVENTS_ERROR: 'GET_SCORECARD_EVENTS_ERROR',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_ERROR: 'DOWNLOAD_ERROR,'
};
