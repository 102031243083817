import * as React from "react";
import "./loader.scss";
import { InjectedIntlProps, injectIntl } from "react-intl";
import messages from "translations/messages";

const LoaderComponent = React.memo((props: InjectedIntlProps) => {
  return <div>{props.intl.formatMessage(messages["loader.pleaseWait"])}</div>;
});

export const Loader = injectIntl(LoaderComponent);
