import { isValidEmail } from 'helpers/helper';
import { IEmployee } from 'models/Employee';
import { ErrorCode } from 'models/error';

export const formatUser = (employee: IEmployee) => {
  return {
    id: employee?.id,
    empId: employee?.employeeId,
    firstName: employee?.firstName,
    lastName: employee?.lastName,
    email: employee?.email,
    title: employee?.title,
    managerName: '',
    managerTitle: '',
    managerEmailId:''
  };
};

export const onValidate = (values: any, previousValues: any, serverErrors: any) => {
  const errors: any = {};

  if (!values.lineManager || values.lineManager.id <= 0) {
    errors.lineManager = 'employees.validation.requiredLineManager';
  }

  return errors;
};

export const onInputValidate = (values: any, currentErrors: any, previousValues: any, serverErrors: any) => {
  const errors: any = {};

  if (currentErrors && currentErrors.lineManager && (!values.lineManager || values.lineManager.id <= 0)) {
    errors.lineManager = 'employees.validation.requiredLineManager';
  }

  validateServerError(errors, values, previousValues, serverErrors);

  return errors;
};

const validateServerError = (errors: any, values: any, previousValues: any, serverErrors: any) => {
  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'employees.validation.inValidEmail';
  }
  if (serverErrors?.length > 0) {
    const lineManagerError = serverErrors.find((x: any) => x.Code === ErrorCode.differentLineManager);
    if (lineManagerError && values.lineManager.id === previousValues.lineManagerId) {
      errors.lineManager = 'employees.validation.differentLineManager';
    }
    const employeeIdError = serverErrors.find((x: any) => x.Code === ErrorCode.uniqueEmployeeId);
    if (employeeIdError && values.employeeId === previousValues.employeeId) {
      errors.employeeId = 'employees.validation.uniqueEmployeeId';
    }
    const employeeEmailError = serverErrors.find((x: any) => x.Code === ErrorCode.uniqueEmployeeEmail);
    if (employeeEmailError && values.email === previousValues.email) {
      errors.email = 'employees.validation.uniqueEmployeeEmail';
    }
  }
};
