import React, { useEffect, useState } from "react";
import authentication from "helpers/react-msal";
import { getTokenExpiry } from "helpers/auth-helper";
import { tokenRenewalTime } from "./consts";

export const RefreshToken: React.FC = () => {
  const [time, setTime] = useState<number>();
  let timer: any;

  const updateRefreshTime = () => {
    const expiryTime = getTokenExpiry();
    const refreshTime = expiryTime - (new Date().getTime() / 1000);
    if (refreshTime <= tokenRenewalTime) {
      authentication.refreshToken(updateRefreshTime);
    } else {
      setTime((refreshTime - tokenRenewalTime) * 1000);
    }
  }

  useEffect(updateRefreshTime, []);
  useEffect(() => {
    if (time) {
      timer = setTimeout(() => {
        authentication.refreshToken(updateRefreshTime);
      }, time);
      return () => clearTimeout(timer);
    }
  }, [time]);
  return null;
}