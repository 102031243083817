import { appHistory } from '../app-history';
import { matchPath } from 'react-router-dom';
import { Privileges } from 'models/privilege';
import { ApprovalStatus } from 'models/Approval';
import { IScoreCardStatus } from 'models/IScoreCardStatus';
import { FMC_FinancialYear } from 'models/constants';

export const history = appHistory();
export const goBack = () => {
  history.go(-1);
};

export const urlParams = {
  id: '/:id',
  id2: '/:id2',
  hash: '/:hash',
  section: '/:section',
  relatedItemId: '/:relatedItemId'
};

export const getParams = (url: string) => {
  const match = matchPath(history.location.pathname, {
    path: url,
    exact: true,
    strict: false
  });
  if (match && match.params) {
    return match.params as any;
  }
  return '';
};

export const getSearchParams = (url: string) => {
  const match = matchPath(history.location.search, {
    path: url,
    exact: true,
    strict: false
  });
  if (match && match.params) {
    return match.params as any;
  }
  return '';
};

export const urlRegEx = {
  guid: '([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})?'
};

export const maxWidthStringMobile = `(max-width: 575.8px)`;

export const getElapsedTime = (date: Date) => {
  if (typeof date === 'string') date = new Date(date);

  const todayDate = new Date();
  const msec = todayDate.getTime() - date.getTime();
  const secs = Math.floor(msec / 1000);
  const mins = Math.floor(secs / 60);
  const hrs = Math.floor(mins / 60);
  const days = Math.floor(hrs / 24);
  const yrs = Math.floor(days / 365);

  return (
    (yrs > 0
      ? yrs + ' yr'
      : days > 0
      ? days + ' day'
      : hrs > 0
      ? hrs + ' hr'
      : mins > 0
      ? mins + ' min'
      : secs + ' sec') + ' ago'
  );
};

export const blankGUID = '00000000-0000-0000-0000-000000000000';

export const isStartDateGreaterThanEndDate = (startDate: Date | undefined, endDate: Date | undefined) => {
  let showValidation = false;
  if (startDate && endDate && startDate > endDate) {
    showValidation = true;
  }
  return showValidation;
};

export const TIMEOUT_FIVE_SECONDS = 5000;
export const TIMEOUT_TWO_SECONDS = 2000;
export const WINDOW_WIDTH_768 = 768;

export const isValidId = (stringToTest: string) => {
  const regexGuid = /^[0-9]{7}$/;
  return regexGuid.test(stringToTest);
};

export const isValidEmail = (email: string) => {
  const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z.]+\.[a-zA-Z]{2,3}$/;
  return regexEmail.test(email);
};

export const getClassNameForStatus = (status: any, userPrivileges: Privileges[]) => {
  const className = '';
  return className;
};

export const mask = '******';

export const getDotColor = (status: string) => {
  switch (status) {
    case ApprovalStatus.Approved:
    case IScoreCardStatus.Active:
      return 'green';
    case ApprovalStatus.Rejected:
      return 'red';
    case IScoreCardStatus.Closed:
      return 'gray';
    default:
      return 'yellow';
  }
};

export const getFinancialYear = () => {
    return Number(localStorage.getItem('financialYear'));
};

export const isFMCFinancialYear = () => {
  return getFinancialYear() >= FMC_FinancialYear;
};
