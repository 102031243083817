// @ts-ignore
import axios from 'axios';
import authentication from 'helpers/react-msal';
import appConfig from 'helpers/config-helper';
import moment from 'moment-timezone';
import { appHistory } from 'app-history';
import routes from 'screens/routes';
import { getFinancialYear } from '../helpers/helper';

const config = appConfig();
const baseUrl = config.REACT_APP_BASE_API ? config.REACT_APP_BASE_API : '';
export const client = axios.create({ baseURL: baseUrl });

const requestHandler = (request: any) => {
  return request;
};

client.interceptors.request.use((request) => requestHandler(request));

const errorHandler = (error: any) => {
  return Promise.reject({ ...error });
};

const successHandler = (response: any) => {
  return response;
};

client.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const getTimeZone = () => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  return timezone;
};

const redirectToAccessDenied = () => {
  const history = appHistory();
  history.push({
    pathname: `${routes.accessDenied}`
  });
};

const redirectToNotFound = () => {
  const history = appHistory();
  history.push({
    pathname: `${routes.notFound}`
  });
};

export const post = (relativeUrl: string, data?: any) => {
  const token = authentication.getAccessToken();
  const payloadData = data && { ...data, timezone: getTimeZone() };
  return client
    .post(baseUrl + relativeUrl, payloadData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
        financialyear: getFinancialYear()
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        redirectToAccessDenied();
      }
      throw error;
    });
};

export const remove = (relativeUrl: string, data?: any) => {
  const token = authentication.getAccessToken();
  return client
    .delete(baseUrl + relativeUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
        financialyear: getFinancialYear()
      },
      data: data // data type must match "Content-Type" header
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        redirectToAccessDenied();
      }
      throw error;
    });
};

export const get = (relativeUrl: string) => {
  const token = authentication.getAccessToken();
  return client
    .get(relativeUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        financialyear: getFinancialYear(),
        timeZone: getTimeZone()
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        redirectToAccessDenied();
      } else if (
        (relativeUrl.toLowerCase().includes('/preview') || relativeUrl.toLowerCase().includes('/edit')) &&
        error.response &&
        error.response.status === 404
      ) {
        redirectToNotFound();
      }
      throw error;
    });
};

export const downloadResource = (fileName: string) => {
  const relativeUrl = `Documents\\${fileName}`;
  getFile(relativeUrl, fileName, 'application/pdf');
};

export const getFile = (relativeUrl: string, fileName: string, type?: string) => {
  const token = authentication.getAccessToken();
  return client
    .get(relativeUrl, {
      headers: {
        Authorization: `bearer ${token}`
      },
      responseType: 'blob'
    })
    .then((response) => {
      const fileUrl = window.URL.createObjectURL(
        type ? new Blob([response.data], { type: type }) : new Blob([response.data])
      );
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileName;
      a.click();
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        redirectToAccessDenied();
      }
      throw error;
    });
};

export const postFileDownloadWithFilter = (relativeUrl: string, data?: any) => {
  const token = authentication.getAccessToken();
  return client
  .post(baseUrl + relativeUrl, data, {
      headers: {
        Authorization: `bearer ${token}`,
        financialyear: getFinancialYear()
      },
      responseType: 'blob'
    })
    .then((response) => {
      const fileUrl = window.URL.createObjectURL(
        data.type ? new Blob([response.data], { type: data.type }) : new Blob([response.data])
      );
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = data.fileName;
      a.click();
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        redirectToAccessDenied();
      }
      throw error;
    });
};



export const put = (relativeUrl: string, data?: any) => {
  const token = authentication.getAccessToken();
  const payloadData = data && { ...data, timezone: getTimeZone() };
  return client
    .put(baseUrl + relativeUrl, payloadData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
        financialyear: getFinancialYear()
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        redirectToAccessDenied();
      }
      throw error;
    });
};

export const postFile = (relativeUrl: string, data?: any) => {
  const token = authentication.getAccessToken();
  return client
    .post(baseUrl + relativeUrl, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
            Authorization: `bearer ${token}`,
            financialyear: getFinancialYear()
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        redirectToAccessDenied();
      }
      throw error;
    });
};
