import * as React from 'react';
import { AppState } from 'state/app-state';
import { connect } from 'react-redux';
import { getUserProfileState } from 'sections/common/redux/selector';
import { User } from 'models/user';
import { FormattedMessage } from 'react-intl';

export interface BodyProps {
  children: JSX.Element | JSX.Element[];
  intl: any;
  userProfile: User;
}
const AppBody = React.memo((props: BodyProps) => {
  return (
    <>
      {props.children}
    </>
  );
});

const mapStateToProps = (state: AppState) => {
  return {
    userProfile: getUserProfileState(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBody);
