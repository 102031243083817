import { addLocaleData } from "react-intl";
import localeEn from "react-intl/locale-data/en";

import messagesGb from "translations/locales/en-GB.json";

addLocaleData([
  ...localeEn
]);

export interface Language{
  key: string;
  display: string;
  data: any;
}

export const defaultLanguage: Language = {
  key: "en-GB",
  display: "English (UK)",
  data: messagesGb,
}

export const languages: Language[] = [
  {
    key: "en-GB",
    display: "English (UK)",
    data: messagesGb,
  }
].sort((a, b) => a.display.localeCompare(b.display));