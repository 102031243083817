export interface IFMCHierarchyLevelFormFields {
  businessSegment: string;
  globalRegion: string[];
  region: string[];
  country: string[];
  managingOffice: string[];
  client: string[];
  location: string[];
  lineOfBusiness: string[];
  functionalUnit: string[];
}

export const defaultUserScopeFMCHierarchyFormFields: IFMCHierarchyLevelFormFields = {
  businessSegment: '',
  globalRegion: [],
  region: [],
  country: [],
  managingOffice: [],
  client: [],
  location: [],
  lineOfBusiness: [],
  functionalUnit: []
};