import { bindActionCreators, Dispatch, Middleware, MiddlewareAPI } from 'redux'
import {actions as toastrActions, ToastrActionCreators, toastType, positionType} from 'react-redux-toastr';
import "./toastr.scss";
import "react-redux-toastr/src/styles/index.scss";
import uniquId from "uniqid";
import { IntlProvider } from 'react-intl';
import { languages } from "translations/languages";
import { getTranslation } from 'helpers/translation-helpers';
import { flatten } from "flat";

const toastPrefix = "TOAST_";
const croutonsPrefix = "CROUTON_";


const actionTypes = {
  TOAST_IN_PROGRESS: `${toastPrefix}IN_PROGRESS`,
  TOAST_COMPLETE: `${toastPrefix}COMPLETE`,
  TOAST_ERROR: `${toastPrefix}ERROR`,
  TOAST_WARNING: `${toastPrefix}WARNING`,
  CROUTON_SAVING: `${croutonsPrefix}SAVING`,
  CROUTON_SAVED: `${croutonsPrefix}SAVED`,
};

const toastrActionTypes = {
  INFO: 'info' as toastType,
  ERROR: 'error' as toastType,
  SUCCESS: 'success' as toastType,
  LIGHT: 'light'  as toastType,
  WARNING: 'warning'  as toastType
}
const toastrPositionTypes = {
  TOP_RIGHT: 'top-right' as positionType,
  TOP_CENTER: 'top-center' as positionType
}

export interface Message{
  id: string;
  title: string;
  message: string;
  locale?: string;
}

export interface Error{
  title: string;
  errors: string[];
  status?: number;
  locale?: string;
}

export interface Warning{
  title: string;
  errors: string[];
  status?: number;
  locale?: string;
}

export const toast = {
  inProgress : (message: Message) => ({type: actionTypes.TOAST_IN_PROGRESS, payload: message}),
  complete: (message: Message) => ({ type: actionTypes.TOAST_COMPLETE, payload: message }),
  error : (error: Error) => ({type: actionTypes.TOAST_ERROR, payload: error} ),
  warning : (warning: Warning) => ({type: actionTypes.TOAST_WARNING, payload: warning} ),
  saving : () => ({type: actionTypes.CROUTON_SAVING}),
  saved: () => ({type: actionTypes.CROUTON_SAVED})
};

let intl: any;

export function getIntl(languageCode?: string) {
  const _locale = languageCode !== undefined && languageCode !== null && languageCode !== '' ? languageCode : 'en-GB';

  if(intl !== undefined && intl !== null && intl.locale === _locale ){
    return intl;
  }
  const foundLanguage = languages.find(l => l.key === _locale);
  const defaultLanguage = languages.find(l => l.key === "en-GB");
  const msg = foundLanguage
    ? foundLanguage.data
    : (defaultLanguage as any).data;
  const intlProvider = new IntlProvider({ locale: _locale, messages: flatten(msg) });
  return intlProvider.getChildContext().intl;
}

export function generateId(){
  return uniquId();
}

export function createToastsMiddleware() {
  let toastr: ToastrActionCreators;

  const inProgress = (message: Message) => {
    toastr.removeByType(toastrActionTypes.INFO);
    intl = getIntl(message.locale);
    toastr.add({
      id: message.id,
      type: toastrActionTypes.INFO,
      title: intl.formatMessage(getTranslation(message.title)),
      position: toastrPositionTypes.TOP_RIGHT,
      message: intl.formatMessage(getTranslation(message.message))
    });
    setTimeout(() => toastr.removeByType(toastrActionTypes.INFO) , 5000);
  }

  const error = (error: Error) => {
    intl = getIntl(error.locale);
    if (navigator.onLine) {
      if(error.status === 403) {
        toastr.removeByType(toastrActionTypes.WARNING);
        error.errors.forEach((errorMessage) => {
          toastr.add({
            type: toastrActionTypes.WARNING,
            title: intl.formatMessage(getTranslation(error.title)),
            position: toastrPositionTypes.TOP_RIGHT,
            message: intl.formatMessage(getTranslation(errorMessage))
          });
        });
        setTimeout(() => toastr.removeByType(toastrActionTypes.WARNING) , 5000);
      }
      else {
        toastr.removeByType(toastrActionTypes.ERROR);
        error.errors.forEach((errorMessage) => {
          toastr.add({
            type: toastrActionTypes.ERROR,
            title: intl.formatMessage(getTranslation(error.title)),
            position: toastrPositionTypes.TOP_RIGHT,
            message: intl.formatMessage(getTranslation(errorMessage))
          });
        });
        setTimeout(() => toastr.removeByType(toastrActionTypes.ERROR) , 5000);
      }
    } else {
      toastr.removeByType(toastrActionTypes.INFO);
      toastr.add({
        type: toastrActionTypes.INFO,
        title: "Offline",
        position: toastrPositionTypes.TOP_RIGHT,
        message: "App running in offline mode"
      });
      setTimeout(() => toastr.removeByType(toastrActionTypes.INFO) , 5000);
    }
  };

  const warning = (warning: Warning) => {
    toastr.removeByType(toastrActionTypes.WARNING);
    intl = getIntl(warning.locale);
    warning.errors.forEach((errorMessage) => {
      toastr.add({
        type: toastrActionTypes.WARNING,
        title: intl.formatMessage(getTranslation(warning.title)),
        position: toastrPositionTypes.TOP_RIGHT,
        message: intl.formatMessage(getTranslation(errorMessage))
      });
    });
    setTimeout(() => toastr.removeByType(toastrActionTypes.INFO) , 5000);
  }

  const complete = (message: Message) => {
    toastr.removeByType(toastrActionTypes.SUCCESS);
    intl = getIntl(message.locale);
    toastr.add({
      type: toastrActionTypes.SUCCESS,
      title: intl.formatMessage(getTranslation(message.title)),
      position: toastrPositionTypes.TOP_RIGHT,
      message: intl.formatMessage(getTranslation(message.message))
    });
    setTimeout(() => toastr.removeByType(toastrActionTypes.SUCCESS) , 5000);
  }

  const saving = () => {
    toastr.removeByType(toastrActionTypes.LIGHT);
    toastr.add({
      id:"saving",
      type: toastrActionTypes.LIGHT,
      position: toastrPositionTypes.TOP_CENTER,
      message: "saving...",
      options: {
        className: "crouton"
      }
    });
    setTimeout(() => toastr.removeByType(toastrActionTypes.LIGHT) , 5000);
  }

  const saved = () => {
    toastr.removeByType(toastrActionTypes.LIGHT);
    toastr.add({
      id:"saved",
      type: toastrActionTypes.LIGHT,
      position: toastrPositionTypes.TOP_CENTER,
      message: "saved",
      options: {
        className: "crouton",
      }
    });
    setTimeout(() => toastr.removeByType(toastrActionTypes.LIGHT) , 5000);
  }

  const toastMiddleware: Middleware = ({ dispatch }: MiddlewareAPI) => (
    next: Dispatch
  ) => action => {

    if((!action.payload) && (action.type === actionTypes.TOAST_IN_PROGRESS || action.type === actionTypes.TOAST_COMPLETE)){
      throw new Error("Command requires commandId in payload");
    }

    if(!toastr){
      toastr = bindActionCreators({ ...toastrActions }, dispatch);
    }

    switch (action.type) {
      case actionTypes.TOAST_IN_PROGRESS:
        inProgress(action.payload);
        break;
      case actionTypes.TOAST_COMPLETE:
        complete(action.payload);
        break;
      case actionTypes.TOAST_ERROR:
        error(action.payload);
        break;
      case actionTypes.TOAST_WARNING:
        warning(action.payload);
        break;
      case actionTypes.CROUTON_SAVING:
        saving();
        break;
      case actionTypes.CROUTON_SAVED:
        saved();
        break;
      default:
        break;
    }

    return next(action);
  }

  return toastMiddleware;
}
