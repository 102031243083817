export const actionTypes = {
  SET_EMPLOYEES_LOADING_TRUE: 'SET_EMPLOYEES_LOADING_TRUE',
  RESET_EMPLOYEES_STATE: 'RESET_EMPLOYEES_STATE',
  RESET_EMPLOYEE_DETAILS_STATE: 'RESET_EMPLOYEE_DETAILS_STATE',
  GET_EMPLOYEE_SUCCESS: 'GET_EMPLOYEE_SUCCESS',
  GET_EMPLOYEE_ERROR: 'GET_EMPLOYEE_ERROR',
  ADD_OR_UPDATE_EMPLOYEE_SUCCESS: 'ADD_OR_UPDATE_EMPLOYEE_SUCCESS',
  ADD_OR_UPDATE_EMPLOYEE_ERROR: 'ADD_OR_UPDATE_EMPLOYEE_ERROR',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_ERROR: 'GET_COUNTRIES_ERROR',
  SET_NEW_EMPLOYEE_FORM: 'SET_NEW_EMPLOYEE_FORM',
  SET_IS_MANAGER_TRUE: 'SET_IS_MANAGER_TRUE',
  RESET_LINE_MANAGER_STATE: 'RESET_LINE_MANAGER_STATE',
  RESET_SEARCHED_USER_STATE: 'RESET_SEARCHED_USER_STATE',
  SEARCH_MANAGERS_SUCCESS: 'SEARCH_MANAGERS_SUCCESS',
  SEARCH_MANAGERS_ERROR: 'SEARCH_MANAGERS_ERROR'
};
