import { HomeState, defaultHomeState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';

export const handlers = {
  [actionTypes.SET_HOME_LOADING_TRUE]: (state: HomeState, payload: any): HomeState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_HOME_STATE]: (state: HomeState, payload: any): HomeState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none
    };
  }
};

const HomeReducer = (state: HomeState = { ...defaultHomeState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default HomeReducer;
