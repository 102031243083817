import { EventType } from 'models/EventType';
import { IRole, IRoleUser } from 'models/IRole';
import { IUser } from 'models/IUser';
import { Notify } from 'models/Notify';

export interface RolesState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  roles: Array<IRole>;
  selectedRole: IRole;
  users: Array<IRoleUser>;
  privileges: Array<string>;
  searchedUsers: Array<IUser>;
}

export const defaultSelectedRole: IRole = {
  id: '',
  name: '',
  description: '',
  users: [],
  privileges: []
};

export const defaultRolesState: RolesState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  roles: [],
  selectedRole: defaultSelectedRole,
  users: [],
  privileges: [],
  searchedUsers: []
};
