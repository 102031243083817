export const actionTypes = {
  SET_FORMS_SEARCH_LOADING_TRUE: 'SET_FORMS_SEARCH_LOADING_TRUE',
  RESET_FORMS_SEARCH_STATE: 'RESET_FORMS_SEARCH_STATE',
  SEARCH_FORMS_SEARCH_SUCCESS: 'SEARCH_FORMS_SEARCH_SUCCESS',
  SEARCH_FORMS_SEARCH_ERROR: 'SEARCH_FORMS_SEARCH_ERROR',
  GET_USER_SCOPE_SUCCESS: 'GET_USER_SCOPE_SUCCESS',
  GET_USER_SCOPE_ERROR: 'GET_USER_SCOPE_ERROR',
  GET_SCORECARD_EDITABLE_FOR_SELECTED_YEAR_FLAG_SUCCESS: 'GET_SCORECARD_EDITABLE_FOR_SELECTED_YEAR_FLAG_SUCCESS',
  GET_SCORECARD_EDITABLE_FOR_SELECTED_YEAR_FLAG_ERROR: 'GET_SCORECARD_EDITABLE_FOR_SELECTED_YEAR_FLAG_ERROR',
  GET_BONUSSCHEMES_SUCCESS: 'GET_BONUSSCHEMES_SUCCESS',
  GET_BONUSSCHEMES_ERROR: 'GET_BONUSSCHEMES_ERROR',
  GET_USER_FMC_SCOPE_SUCCESS: 'GET_USER_FMC_SCOPE_SUCCESS',
  GET_USER_FMC_SCOPE_ERROR: 'GET_USER_FMC_SCOPE_ERROR',
  SEARCH_FORMS_FMC_SEARCH_SUCCESS: 'SEARCH_FORMS_FMC_SEARCH_SUCCESS',
  SEARCH_FORMS_FMC_SEARCH_ERROR: 'SEARCH_FORMS_FMC_SEARCH_ERROR',
};
