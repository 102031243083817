import { connect } from 'react-redux';
import { AuthorisationComponent } from './authorisation';
import { AppState } from 'state/app-state';
import { getUserPrivileges } from 'sections/common/redux/selector';

const mapStateToProps = (state: AppState) => {
  return {
    userPrivileges: getUserPrivileges(state)
  };
};

export const Authorised = connect(mapStateToProps)(AuthorisationComponent);
