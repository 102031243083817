// @ts-ignore
import authentication from "helpers/react-msal";
import jwtDecode from "jwt-decode";
import { DecodedToken } from "../models/decodedToken";

export const getDisplayName = () => {
  const token = authentication.getAccessToken();
  const decoded: DecodedToken = jwtDecode(token);
  return decoded.name;
}

export const getUserEmail = () => {
  try {
    const token = authentication.getAccessToken();
    const decodedToken: DecodedToken = jwtDecode(token);
    return decodedToken.sub;
  } catch{
    return "";
  }
}

export const getTokenExpiry = () => {
  const token = authentication.getAccessToken();
  const decodedToken: DecodedToken = jwtDecode(token);
  return decodedToken.exp;
}
