import { EventType } from 'models/EventType';
import { defaultUser, IUser } from 'models/IUser';
import { Notify } from 'models/Notify';
import { IOverlapFoForm } from 'models/IOverlapFoForm';
import { IScopeNodeName, IScopePath, ScopePathNodes } from 'models/LevelMapping';
import { IOption } from 'models/IOption';
import { defaultFinancialFigures, defaultTwcFigures, IScoreCardFinancialFigures, ITWCFigures } from 'models/IScoreCardFinancialFigures';
import { IBonusScheme, IDefaultFODates, IFinancialObjectiveFormFields, INonFinancialObjectives, ScopeType } from 'models/IFinancialObjective';
import { IPreviewFO } from 'models/IPreviewFO';
import { IScoreCardStatus } from 'models/IScoreCardStatus';

export interface FinancialObjectiveState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  users: Array<IUser>;
  overlappedForms: Array<IOverlapFoForm>;
  availableScopePaths: Array<IScopePath>;
  scopeNodeNames: Array<IScopeNodeName>;
  multiJurisdictionComments: Array<IOption>;
  financialFigures: IScoreCardFinancialFigures;
  financialObjective: IPreviewFO;
  financialObjectiveFormFields: IFinancialObjectiveFormFields;
  defaultDates: IDefaultFODates;
  bonusSchemes: IBonusScheme[];
  financeDirectors: Array<IOption>;
  userErrors: string;
  nfoGoals:any[],
  twcFigures:ITWCFigures
}

export const defaultNonFinancialObjectives: INonFinancialObjectives = {
  subject1: '',
  descriptionText1: '',
  descriptionHtml1: '',
  assessment1: null,
  subject2: '',
  descriptionText2: '',
  descriptionHtml2: '',
  assessment2: null,
  subject3: '',
  descriptionText3: '',
  descriptionHtml3: '',
  assessment3: null,
  subject4: '',
  descriptionText4: '',
  descriptionHtml4: '',
  assessment4: null,
  subject5: '',
  descriptionText5: '',
  descriptionHtml5: '',
  assessment5: null,
  attachments: [],
  attachmentsToDelete: [],
  isSubmitted:false
}

export const defaultFO: IPreviewFO = {
  id: -1,
  draft: true,
  user: defaultUser,
  status: IScoreCardStatus.Active,
  bonusScheme: '',
  createdBy:'',
  createdOn:'',
  updatedBy:'',
  updatedOn:'',
  reasonForStatusChange: '',
  formPeriod: '',
  startDate: '',
  endDate: '',
  financialObjectives: {
    level1Label: [],
    level1Figure: '0',
    level1Contribution: 0,
    level2Label: [],
    level2Figure: '0',
    level2Contribution: 0,
    revenueLabel: [],
    revenueFigure: '0',
    revenueContribution: 0,
    tradeWorkingCapitalARLabel: [],
    tradeWorkingCapitalARFigure: '0',
    tradeWorkingCapitalARContribution: 0,
    isManualOverride: false,
    canReadLevel2Figure: true,
    manualOverrideOtherCommentJustification: '',
    isTradeWorkingCapitalApplicable:false,    
    tradeWorkingCapitalUBRLabel: [],
    tradeWorkingCapitalUBRFigure: '0',
    tradeWorkingCapitalUBRContribution: 0,
  },
  nonFinancialObjectives: defaultNonFinancialObjectives,
  currentUserCanApprove: false,
  approvals: [],
  canEditFormParts: [],
  scope: {
    scopeType: ScopeType.TotalContract,
    scopeComment: ''
  },
  selectedScopePathNodes: new ScopePathNodes(),
  bonusSchemeId: 0,
  canViewNFOGoals:false,
  canShowNFOSaveSubmitButton:false,
  canViewNFOAssessments:false,
  canEditNFOAssessments:false,
};

export const defaultFinancialObjectiveFormFields: IFinancialObjectiveFormFields = {
  user: defaultUser,
  startDate: '',
  endDate: '',
  status: IScoreCardStatus.Active,
  subSegment: [],
  divisions: [],
  vicePres: [],
  subDivisions: [],
  teams: [],
  countries: [],
  clients: [],
  multiJurisdiction: false,
  multiJurisdictionComments: {
    id: ''
  },
  scope: ScopeType.TotalContract,
  comments: '',
  financialFigures: defaultFinancialFigures,
  reasonForStatusChange: '',
  manualOverrideOtherCommentJustification: '',
  bonusSchemeId:0,
  nonFinancialObjectives: defaultNonFinancialObjectives,  
  financeDirector: {id: ''},
};

export const defaultDatesState: IDefaultFODates = {
  startDate: '',
  endDate: ''
};

export const defaultFinancialObjectiveState: FinancialObjectiveState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  users: [defaultUser],
  overlappedForms: [],
  availableScopePaths: [],
  scopeNodeNames: [],
  multiJurisdictionComments: [],
  financialFigures: defaultFinancialFigures,
  financialObjective: defaultFO,
  financialObjectiveFormFields: defaultFinancialObjectiveFormFields,
  defaultDates: defaultDatesState,
  bonusSchemes:[],
  financeDirectors:[],
  userErrors:'',
  nfoGoals:[],
  twcFigures: defaultTwcFigures
};
