import { EventType } from 'models/EventType';
import { IAchievement, IPerformanceRating, ITMData } from 'models/IAchievement';
import { IScopeNodeName, ScopePathNodes } from 'models/LevelMapping';
import { Notify } from 'models/Notify';
import { IBonusScheme } from 'models/IFinancialObjective';

export interface CalculatorState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  tmData: ITMData | null;
  achievements: Array<IAchievement>;
  offset: number;
  limit: number;
  totalNoOfRecords: number;
  financialYear: number;
  riseValueData:Array<IPerformanceRating>;
  performanceRatings: Array<IPerformanceRating>;
  scope: ScopePathNodes;
  scopeNodeNames: Array<IScopeNodeName>;
  yearToDateCalculationMonth: number;
  bonusSchemes: Array<IBonusScheme>;
}

export const defaultCalculatorState: CalculatorState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  tmData: null,
  achievements: [],
  offset: 0,
  limit: 10,
  totalNoOfRecords: 0,
  financialYear: new Date().getFullYear(),
  riseValueData:[],
  performanceRatings: [],
  scope: new ScopePathNodes(),
  scopeNodeNames: [],
  yearToDateCalculationMonth : 12,
  bonusSchemes: []
};
