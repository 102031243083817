import { ApprovalStatus } from './Approval';
import { IScopeLevelFormFields } from './IScopLevelFormFields';
import { IScoreCardFinancialFigures, IScoreCardFormFinancialFigures } from './IScoreCardFinancialFigures';
import { IUser } from './IUser';
import { FileModel } from './fileModel';

export interface IFinancialObjective {
  id: string;
  empId: string;
  user: IUser;
  formPeriod: number;
  status: string;
  reasonForStatusChange?: string;
  startDate?: Date;
  endDate?: Date;
  employeeApprovalStatus: ApprovalStatus;
  managerApprovalStatus: ApprovalStatus;
  canEdit: boolean;
  canPreview: boolean;
  financialFigures: {
    level1LabelIsAmended: boolean;
    level2LabelIsAmended: boolean;
    revenueLabelIsAmended: boolean;
    tradeWorkingCapitalARLabelIsAmended: boolean;
    tradeWorkingCapitalUBRLabelIsAmended: boolean;
    level1FigureIsAmended: boolean;
    level2FigureIsAmended: boolean;
    revenueFigureIsAmended: boolean;
    tradeWorkingCapitalARFigureIsAmended: boolean;
    tradeWorkingCapitalUBRFigureIsAmended: boolean;
  };
  nonFinancialObjectives: INonFinancialObjectives;
  isManualOverride: boolean;
  financeDirectorJobTitle: string;
  financeDirectorApprovalStatus: ApprovalStatus;
  financeDirectorName: string;
}

export enum Assessment {
  Pass = 'Pass',
  Fail = 'Fail'
}

export enum ScopeType {
  TotalContract = 'TotalContract',
  ProjectOnly = 'ProjectOnly'
}

export enum BonusScheme {
  Local_DCS_Scorecard_Business = 1,
  Finance_Business = 2,
  Finance_Controllership = 3,
  Finance_FP_A = 4,
  Finance_PJM = 5,
  Finance_Business_DCS =6,
  Finance_Business_Local = 7
}
export interface INonFinancialObjectives {
  subject1: string;
  descriptionText1: string;
  descriptionHtml1: string;
  assessment1: Assessment | null;
  subject2: string;
  descriptionText2: string;
  descriptionHtml2: string;
  assessment2: Assessment | null;
  subject3: string;
  descriptionText3: string;
  descriptionHtml3: string;
  assessment3: Assessment | null;
  subject4: string;
  descriptionText4: string;
  descriptionHtml4: string;
  assessment4: Assessment | null;
  subject5: string;
  descriptionText5: string;
  descriptionHtml5: string;
  assessment5: Assessment | null;
  attachments: Array<FileModel>;
  attachmentsToDelete: Array<FileModel> | [];
  isSubmitted: boolean;
}

export interface INonFinancialObjectivesFormFields{
  isSubmitted : boolean;
  nonFinancialObjectives :INonFinancialObjectives;
}

export interface IFinancialObjectiveFormFields extends IScopeLevelFormFields {
  user: IUser;
  startDate: string | Date;
  endDate: string | Date;
  status: string;
  multiJurisdiction: boolean;
  multiJurisdictionComments: {
    id: string;
  };
  scope: ScopeType;
  comments: string;
  financialFigures: IScoreCardFinancialFigures;
  reasonForStatusChange: string;
  manualOverrideOtherCommentJustification: string;
  bonusSchemeId: number;
  nonFinancialObjectives: INonFinancialObjectives;
  financeDirector: { id: string; };
}

export interface IFinancialObjectiveForm {
  userId: number;
  draft: boolean;
  status: string;
  ScoreCardStatusChange: {
    comment: string;
  };
  periodStartDate?: string;
  periodEndDate?: string;
  scope: {
    subSegments: string[];
    divisions: string[];
    vicePres: string[];
    subdivisions: string[];
    teams: string[];
    countries: string[];
    clients: string[];
    scopeType: ScopeType;
    scopeComment: string;
  };
  financialFigures: IScoreCardFormFinancialFigures;
  financialYear: number;
  bonusSchemeId: number;
  nonFinancialObjectives: INonFinancialObjectives;
}

export interface IDefaultFODates {
  startDate: string;
  endDate: string;
}

export interface IBonusScheme {
  id: number;
  schemeCode: string;
  schemeName: string;
  isActive: boolean;
}