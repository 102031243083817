export interface IUser {
  id: number;
  empId: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  managerName: string;
  managerTitle: string;
  managerEmailId: string;
  IsEligibleForBonus?:boolean;
}

export const defaultUser: IUser = {
  id: -1,
  empId: '-',
  firstName: '-',
  lastName: '-',
  email: '-',
  title: '-',
  managerName: '-',
  managerTitle: '-',
  managerEmailId:'-',
  IsEligibleForBonus:false
};
