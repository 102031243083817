import { Notify } from 'models/Notify';
import { Dispatch } from 'redux';
import { get } from 'services/api-clients';
import { actionTypes } from './actions';

const getUserProfileSuccess = (response: any) => {
  return {
    type: actionTypes.GET_USER_PROFILE_SUCCESS,
    payload: response.data
  };
};

const getUserProfileError = (error: string) => {
  return {
    type: actionTypes.GET_USER_PROFILE_ERROR,
    payload: error
  };
};
export const getUserProfile = () => {
  return (dispatch: Dispatch) => {
    get('me')
      .then((response) => {
        /* istanbul ignore next */
        dispatch(getUserProfileSuccess(response.data));
      })
      .catch((error) => {
        /* istanbul ignore next */
        dispatch(getUserProfileError(error));
      });
  };
};

const getPrivilegesSuccess = (response: any) => {
  return {
    type: actionTypes.GET_PRIVILEGES_SUCCESS,
    payload: response.data
  };
};

const getPrivilegesError = (error: any, notify: Notify) => {
  return {
    type: actionTypes.GET_PRIVILEGES_ERROR,
    payload: {
      response: [],
      error: error,
      notify: notify
    }
  };
};

export const getPrivileges = () => {
  return (dispatch: Dispatch) => {
    get(`Privilege`)
      .then((response) => {
        /* istanbul ignore next */
        if (response.data?.httpStatusCode === 200) {
          dispatch(getPrivilegesSuccess(response.data));
        }
      })
      .catch((error) => {
        /* istanbul ignore next */
        if (error.response && error.response.data && error.response.data.errors) {
          return dispatch(getPrivilegesError(error.response.data.errors, Notify.none));
        }
        dispatch(getPrivilegesError(error, Notify.internalServerError));
      });
  };
};

const getFinancialYearsSuccess = (response: any) => {
  return {
    type: actionTypes.GET_FINANCIAL_YEARS_SUCCESS,
    payload: response.data
  };
};

const getFinancialYearsError = (error: any, notify: Notify) => {
  return {
    type: actionTypes.GET_FINANCIAL_YEARS_ERROR,
    payload: {
      response: [],
      error: error,
      notify: notify
    }
  };
};

export const getFinancialYears = () => {
  return (dispatch: Dispatch) => {
    get(`FinancialYear/GetYears`)
      .then((response) => {
        if (response.data?.httpStatusCode === 200) {
          dispatch(getFinancialYearsSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          return dispatch(getFinancialYearsError(error.response.data.errors, Notify.none));
        }
        dispatch(getFinancialYearsError(error, Notify.internalServerError));
      });
  };
};

export const setFinancialYear = (financialYear: number) => {
  return {
    type: actionTypes.SET_FINANCIAL_YEAR,
    payload: Number(financialYear)
  };
};
