import { defaultEmployee, defaultEmployeeFormFields, ICountry, IEmployee, IEmployeeFormFields } from 'models/Employee';
import { IError } from 'models/error';
import { EventType } from 'models/EventType';
import { IUser } from 'models/IUser';
import { Notify } from 'models/Notify';

export interface EmployeesState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  searchedUsers: Array<IUser>;
  searchedManagers: Array<IUser>;
  countries: Array<ICountry>;
  employee: IEmployee;
  employeeFormFields: IEmployeeFormFields;
  errors: Array<IError>;
}

export const defaultEmployeesState: EmployeesState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  searchedUsers: [],
  searchedManagers: [],
  countries: [],
  employee: defaultEmployee,
  employeeFormFields: defaultEmployeeFormFields,
  errors: []
};
