import { MinimumFinancialYear } from 'models/calculator';
import { defaultUserProfile, User } from 'models/user';

export interface CommonState {
  userProfile: User;
  loading: boolean;
  success: boolean;
  allPrivileges: Array<string>;
  allFinancialYears: Array<number>;
  selectedFinancialYear: number;
}

export const defaultCommonState: CommonState = {
  userProfile: defaultUserProfile,
  loading: false,
  success: false,
  allPrivileges: [],
  allFinancialYears: [],
  selectedFinancialYear: MinimumFinancialYear
};
