/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unused-expressions */
import 'react-app-polyfill/ie9';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'styles/fonts/calibre-regular.woff2';
import 'styles/fonts/calibre-regular.woff';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Routing from './routing';
import { initializeStore } from './store';
import 'styles/fontaweome';
import 'styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as serviceWorker from './serviceWorker';
import './services/window.prompt';
// @ts-ignore
import authentication from 'helpers/react-msal';
import appConfig from 'helpers/config-helper';
import { NetworkConnectionContainer } from 'sections/networkConnection/container';
import { history, TIMEOUT_TWO_SECONDS } from 'helpers/helper';
import { getUserEmail } from 'helpers/auth-helper';
import { appInsight, getAppInsights } from 'middleware/telemetry/telemetry.service';
import { LogoutTimer } from 'sections/logoutTimer';
import { RefreshToken } from 'sections/refreshToken';
import { languages } from 'translations/languages';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';

const loginRedirectUrl = localStorage.getItem('loginRedirectUrl');
if (!loginRedirectUrl) {
  localStorage.setItem('loginRedirectUrl', window.location.pathname);
}

const config = appConfig();
appInsight.initialize(config.REACT_APP_INSTRUMENTATION_KEY);

// Added a fix to solve /null redirect issue and hadling cacheLocation as localStorage
if (localStorage.getItem('msal.error') === 'invalid_request' && localStorage.getItem('msal.error.description')) {
  const allKeys = Object.keys(localStorage);
  allKeys.forEach((value) => {
    if (!(value === 'appVersion' || value === 'loginRedirectUrl')) {
      localStorage.removeItem(value);
    }
  });
  window.location.href = window.location.origin;
  window.location.reload();
}

const renderApp = () => {
  const store = initializeStore();
  if (window.location.pathname.includes('/null')) {
    const loginRedirectUrl = localStorage.getItem('loginRedirectUrl');
    if (loginRedirectUrl) {
      history.push(loginRedirectUrl);
    } else {
      history.push('/');
    }
  }
  localStorage.removeItem('loginRedirectUrl');
  //const languageCode: string = navigator.language; // language without region code
  const languageCode = 'en-GB';
  const defaultLanguage = languages.find((l) => l.key === 'en-GB');
  const msg = (defaultLanguage as any).data;
  return (
    <Provider store={store}>
      <>
        <IntlProvider locale={languageCode} messages={flatten(msg)}>
          <div>
            <ReduxToastr
              timeOut={TIMEOUT_TWO_SECONDS}
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
            />
            <Routing />
            <RefreshToken />
            <LogoutTimer />
          </div>
        </IntlProvider>
      </>
    </Provider>
  );
};

async function init() {
  const scope = `api://` + config.REACT_APP_AUTH_APPID + `/Files.Read`;
  await authentication.initialize({
    instance: config.REACT_APP_AUTH_INSTANCE,
    tenant: config.REACT_APP_AUTH_TENANT,
    applicationId: config.REACT_APP_AUTH_APPID,
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    scopes: [scope],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin
  });
  
  authentication.run(() => {
    ReactDOM.render(renderApp(), document.getElementById('root') as HTMLElement);
    if (getAppInsights()) {
      getAppInsights().setAuthenticatedUserContext(getUserEmail());
    }
    serviceWorker.register();
  });
}

init();

