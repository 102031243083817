export const actionTypes = {
  SET_MANAGE_REPORT_LOADING_TRUE: 'SET_MANAGE_REPORT_LOADING_TRUE',
  RESET_MANAGE_REPORT_STATE: 'RESET_MANAGE_REPORT_STATE',
  RESET_USER_DETAILS_STATE: 'RESET_USER_DETAILS_STATE',
  GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
  GET_USER_BY_ID_ERROR: 'GET_USER_BY_ID_ERROR',
  UPDATE_USER_REPORT_SCOPE_SUCCESS: 'UPDATE_USER_REPORT_SCOPE_SUCCESS',
  UPDATE_USER_REPORT_SCOPE_ERROR: 'UPDATE_USER_REPORT_SCOPE_ERROR',
  UPDATE_USER_REPORT_FMC_SCOPE_SUCCESS: 'UPDATE_USER_REPORT_FMC_SCOPE_SUCCESS',
  UPDATE_USER_REPORT_FMC_SCOPE_ERROR: 'UPDATE_USER_REPORT_FMC_SCOPE_ERROR'
};
