import messages from 'translations/messages';
import { InjectedIntl } from 'react-intl';

export const getTranslation = (label: string, injectedMessages?: any) => {
  const messagesToUse = injectedMessages ? injectedMessages : messages;
  const foundMessage = (messagesToUse as any)[label];

  if (foundMessage) {
    return foundMessage;
  }

  return messagesToUse['translation.notfound'];
};

// This method tries to translate a text if its translation is available. It will
// return the label as it is if no translation is found.
export const tryToTranslate = (label: string, intl: InjectedIntl) => {
  const foundMessage = (messages as any)[label];
  if (foundMessage) {
    return intl.formatMessage(getTranslation(label));
  } else {
    return label;
  }
};
