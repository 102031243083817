import { NetworkConnectionState, defaultNetworkConnectionState } from "./state";
import { actionTypes } from "./actions";
import { AppAction } from "../../../models/action";

const handlers = {
  [actionTypes.UPDATE_NETWORKCONNECTION]: (
    state: NetworkConnectionState,
    payload: boolean
  ): NetworkConnectionState => {
    return { 
      isConnected: payload
    }
  },
};

export const NetworkConnectionReducer = (
  state: NetworkConnectionState = { ...defaultNetworkConnectionState },
  action: AppAction
) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default NetworkConnectionReducer;
