import { ManageReportState, defaultManageReportState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { actionTypes as financialObjectivesActionTypes } from '../../financialObjective/redux/actions';
import { defaultUserScopeFormFields, defaultUserScopeHierarchy } from 'models/user';
import { IFMCHierarchyResponse, IScopeHierarchyResponse } from 'sections/financialObjective/models.client';
import { getScopeLevel, IScopeNodeName, IScopePath, ScopePathNodes } from 'models/LevelMapping';
import { getFMCHierarchyLevel, IFMCHierarchyNodeName, IFMCHierarchy } from 'models/FMCHierarchyLevelMapping';
import { defaultUserScopeFMCHierarchyFormFields } from 'models/IFMCHierarchyLevelFormFields';

export const handlers = {
  [actionTypes.SET_MANAGE_REPORT_LOADING_TRUE]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_MANAGE_REPORT_STATE]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none
    };
  },
  [actionTypes.RESET_USER_DETAILS_STATE]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      userScopeHierarchy: defaultUserScopeHierarchy,
      userScopeInitialState: defaultUserScopeFormFields,
      userScopeFMCHierarchyInitialState: defaultUserScopeFMCHierarchyFormFields,
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_SUCCESS]: (
    state: ManageReportState,
    payload: any
  ): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.userList,
      searchedUsers: payload
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_ERROR]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.userList,
      searchedUsers: payload.response
    };
  },
  [actionTypes.GET_USER_BY_ID_SUCCESS]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      selectedScopePathNodes: new ScopePathNodes({
        subSegments: payload.reportingScope?.subSegment ? [payload.reportingScope?.subSegment] : [], // change scope to reportingScope
        clients: payload.reportingScope?.clients ?? [],
        countries: payload.reportingScope?.countries ?? [],
        divisions: payload.reportingScope?.divisions ?? [],
        subDivisions: payload.reportingScope?.subDivisions ?? [],
        teams: payload.reportingScope?.teams ?? []
      }),
      userScopeInitialState: {
        clients: payload.reportingScope?.clients ?? [],
        countries: payload.reportingScope?.countries ?? [],
        divisions: payload.reportingScope?.divisions ?? [],
        vicePres: payload.reportingScope?.vicePres ?? [],
        subDivisions: payload.reportingScope?.subDivisions ?? [],
        subSegment: payload.reportingScope?.subSegment ?? '',
        teams: payload.reportingScope?.teams ?? []
      },
      userScopeFMCHierarchyInitialState: {
        businessSegment: payload.reportingScopeFMC?.businessSegment?? '',
        globalRegion: payload.reportingScopeFMC?.globalRegion ?? [],
        region: payload.reportingScopeFMC?.region ?? [],
        country: payload.reportingScopeFMC?.country ?? [],
        managingOffice: payload.reportingScopeFMC?.managingOffice ?? [],
        client: payload.reportingScopeFMC?.client ?? [],
        location: payload.reportingScopeFMC?.location ?? [],
        lineOfBusiness: payload.reportingScopeFMC?.lineOfBusiness ?? [],
        functionalUnit: payload.reportingScopeFMC?.functionalUnit ?? [],
      }
    };
  },
  [actionTypes.GET_USER_BY_ID_ERROR]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      userScopeHierarchy: defaultUserScopeHierarchy,
    };
  },
  [financialObjectivesActionTypes.GET_SCOPE_HIERARCHY_SUCCESS]: (
    state: ManageReportState,
    payload: IScopeHierarchyResponse
  ): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      availableScopePaths: payload.scopeHierarchy.map(
        (p) =>
          ({
            subSegment: p.subSegmentCode,
            client: p.clientCode,
            country: p.countryCode,
            division: p.divisionCode,
            subDivision: p.subDivisionCode,
            team: p.teamCode
          } as IScopePath)
      ),
      scopeNodeNames: payload.codeNames.map(
        (c) => ({ code: c.code, name: c.name, level: getScopeLevel(c.level) } as IScopeNodeName)
      )
    };
  },
  [financialObjectivesActionTypes.GET_SCOPE_HIERARCHY_ERROR]: (
    state: ManageReportState,
    payload: any
  ): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      availableScopePaths: []
    };
  },
  [actionTypes.UPDATE_USER_REPORT_SCOPE_SUCCESS]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      userScopeHierarchy: payload.response,
      userScopeInitialState: {
        clients: payload.response.clients,
        countries: payload.response.countries,
        divisions: payload.response.divisions,
        vicePres: payload.response.vicePres,
        subDivisions: payload.response.subDivisions,
        subSegment: payload.response.subSegment,
        teams: payload.response.teams
      }
    };
  },
  [actionTypes.UPDATE_USER_REPORT_SCOPE_ERROR]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  
  [financialObjectivesActionTypes.GET_FMC_HIERARCHY_SUCCESS]: (state: ManageReportState, payload: IFMCHierarchyResponse): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      availableFMCHierarchy: payload.fmcHierarchy.map((p) => ({
        businessSegment: p.businessSegmentCode,
        globalRegion: p.globalRegionCode,
        region: p.regionCode,
        country: p.countryCode,
        managingOffice: p.managingOfficeCode,
        client: p.clientCode,
        location: p.locationCode,
        lineOfBusiness: p.lineOfBusinessCode,
        functionalUnit: p.functionalUnitCode
      } as IFMCHierarchy)),
      fmcHierarchyNodeNames: payload.codeNames.map(c => ({ code: c.code, name: c.name, level: getFMCHierarchyLevel(c.level) } as IFMCHierarchyNodeName)),
    };
  },
  [financialObjectivesActionTypes.GET_FMC_HIERARCHY_ERROR]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      availableFMCHierarchy: [],
    };
  },
  [actionTypes.UPDATE_USER_REPORT_FMC_SCOPE_SUCCESS]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      userScopeFMCHierarchyInitialState: {
        businessSegment: payload.response.businessSegment,
        globalRegion: payload.response.globalRegion,
        region: payload.response.region,
        country: payload.response.country,
        managingOffice: payload.response.managingOffice,
        client: payload.response.client,
        location: payload.response.location,
        lineOfBusiness: payload.response.lineOfBusiness,
        functionalUnit: payload.response.functionalUnit,
      }
    };
  },
  [actionTypes.UPDATE_USER_REPORT_FMC_SCOPE_ERROR]: (state: ManageReportState, payload: any): ManageReportState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
};

const ManageReportReducer = (state: ManageReportState = { ...defaultManageReportState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default ManageReportReducer;
