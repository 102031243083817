export const actionTypes = {
  SET_ROLES_LOADING_TRUE: 'SET_ROLES_LOADING_TRUE',
  RESET_ROLES_STATE: 'RESET_ROLES_STATE',
  GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
  GET_ALL_ROLES_ERROR: 'GET_ALL_ROLES_ERROR',
  SET_NEW_ROLE_FORM: 'SET_NEW_ROLE_FORM',
  GET_SELECTED_ROLE_SUCCESS: 'GET_SELECTED_ROLE_SUCCESS',
  GET_SELECTED_ROLE_ERROR: 'GET_SELECTED_ROLE_ERROR',
  SET_SELECTED_ROLE_USERS: 'SET_SELECTED_ROLE_USERS',
  SET_SELECETD_ROLE_PRIVILEGES: 'SET_SELECETD_ROLE_PRIVILEGES',
  ADD_ROLE_SUCESS: 'ADD_ROLE_SUCESS',
  ADD_ROLE_ERROR: 'ADD_ROLE_ERROR',
  EDIT_ROLE_SUCESS: 'EDIT_ROLE_SUCESS',
  EDIT_ROLE_ERROR: 'EDIT_ROLE_ERROR',
  ADD_PRIVILEGE_TO_ROLE_SUCCESS: 'ADD_PRIVILEGE_TO_ROLE_SUCCESS',
  ADD_PRIVILEGE_TO_ROLE_ERROR: 'ADD_PRIVILEGE_TO_ROLE_ERROR',
  REMOVE_PRIVILEGE_FROM_ROLE_SUCCESS: 'REMOVE_PRIVILEGE_FROM_ROLE_SUCCESS',
  REMOVE_PRIVILEGE_FROM_ROLE_ERROR: 'REMOVE_PRIVILEGE_FROM_ROLE_ERROR',
  ADD_USER_TO_ROLE_SUCCESS: 'ADD_USER_TO_ROLE_SUCCESS',
  ADD_USER_TO_ROLE_ERROR: 'ADD_USER_TO_ROLE_ERROR',
  REMOVE_USER_FROM_ROLE_SUCCESS: 'REMOVE_USER_FROM_ROLE_SUCCESS',
  REMOVE_USER_FROM_ROLE_ERROR: 'REMOVE_USER_FROM_ROLE_ERROR'
};
