export const actionTypes = {
  SET_TMUPLOAD_LOADING_TRUE: 'SET_TMUPLOAD_LOADING_TRUE',
  RESET_TMUPLOAD_STATE: 'RESET_TMUPLOAD_STATE',
  BONUS_CALCULATION_SIMULATION_SUCCESS: 'BONUS_CALCULATION_SIMULATION_SUCCESS',
  BONUS_CALCULATION_SIMULATION_ERROR: 'BONUS_CALCULATION_SIMULATION_ERROR',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  GET_HISTORY_ERROR: 'GET_HISTORY_ERROR',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_ERROR: 'DOWNLOAD_ERROR',
  RUN_BONUS_CALCULATION_SUCCESS: 'RUN_BONUS_CALCULATION_SUCCESS',
  RUN_BONUS_CALCULATION_ERROR: 'RUN_BONUS_CALCULATION_ERROR',
  RUN_BONUS_CALCULATION_RATING_SUCCESS: 'RUN_BONUS_CALCULATION_SUCCESS',
  RUN_BONUS_CALCULATION_RATING_ERROR: 'RUN_BONUS_CALCULATION_ERROR',
};
