import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { history } from "helpers/helper";

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (instrumentationKey: string) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return;
    }
    
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in config.js')
    }

    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: history
          }
        }
      }
    });

    appInsights.loadAppInsights();
  };

  return { reactPlugin, appInsights, initialize };
};

export default (component: React.ComponentType) => withAITracking(reactPlugin, component)
export const appInsight = createTelemetryService();
export const getAppInsights = () => appInsights;
