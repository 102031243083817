import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Router, Route, Switch } from 'react-router-dom';
import Loading from 'common/loading';
import { DefaultLayout } from './common/layout/default/defaultLayout';
import { ErrorToastr } from './common/errors';
import { history, urlParams } from 'helpers/helper';
import routes from 'screens/routes';
import Loadable from 'react-loadable';
import withAppInsights from 'middleware/telemetry/telemetry.service';

// Lazy load screen implementation
const Home = Loadable({
  loader: () => import('screens/formsSearch'),
  loading: () => <Loading />
});

const Roles = Loadable({
  loader: () => import('screens/roles'),
  loading: () => <Loading />
});

// Lazy load screen implementation
const FinancialObjective = Loadable({
  loader: () => import('screens/financialObjective'),
  loading: () => <Loading />
});

// Lazy load screen implementation
const PreviewFO = Loadable({
  loader: () => import('screens/previewFO'),
  loading: () => <Loading />
});

// Lazy load screen implementation
const Calculator = Loadable({
  loader: () => import('screens/calculator'),
  loading: () => <Loading />
});

const Users = Loadable({
  loader: () => import('screens/users'),
  loading: () => <Loading />
});

const AccessDenied = Loadable({
  loader: () => import('screens/errors/accessDenied'),
  loading: () => <Loading />
});

const NotFound = Loadable({
  loader: () => import('screens/errors/notFound'),
  loading: () => <Loading />
});

const Employees = Loadable({
  loader: () => import('screens/employees'),
  loading: () => <Loading />
});

const TMUpload = Loadable({
  loader: () => import('screens/tmUpload'),
  loading: () => <Loading />
});

const ManageReport = Loadable({
  loader: () => import('screens/manageReport'),
  loading: () => <Loading />
});

class Routing extends React.Component<ReactIntl.InjectedIntlProps> {
  public state = {
    isOpen: false
  };

  public render() {
    return (
      <Router history={history}>
        <ErrorToastr>
          <Switch>
            <Route
              exact
              path={routes.home}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="Home">
                  <Home />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.financialObjective}${urlParams.id}?`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="FinancialObjective">
                  <FinancialObjective />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.previewFO}${urlParams.id}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="PreviewFO">
                  <PreviewFO />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.roles}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="Roles">
                  <Roles />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.calculator}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="Calculator">
                  <Calculator />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.users}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="Users">
                  <Users />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.accessDenied}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="AccessDenied">
                  <AccessDenied />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.notFound}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="NotFound">
                  <NotFound />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.employees}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="Employees">
                  <Employees />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.tm1Upload}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="TMUpload">
                  <TMUpload />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path={`${routes.manageReport}`}
              component={() => (
                <DefaultLayout isHidden={false} sideMenuDockable={false} page="ManageReport">
                  <ManageReport />
                </DefaultLayout>
              )}
            />
          </Switch>
        </ErrorToastr>
      </Router>
    );
  }
}

export default withAppInsights(injectIntl(Routing));
