import { EventType } from 'models/EventType';
import { IRole, IUserRole } from 'models/IRole';
import { IScopeLevelFormFields } from 'models/IScopLevelFormFields';
import { IUser } from 'models/IUser';
import { IScopeNodeName, IScopePath, ScopePathNodes } from 'models/LevelMapping';
import { Notify } from 'models/Notify';
import {
  defaultUserScopeFMCHierarchy,
  defaultUserScopeFormFields,
  defaultUserScopeHierarchy,
  UserScopeHierarchy,
  UserScopeFMCHierarchy
} from 'models/user';
import { IFMCHierarchyNodeName, IFMCHierarchy, FMCHierarchyNodes } from 'models/FMCHierarchyLevelMapping';
import { IFMCHierarchyLevelFormFields, defaultUserScopeFMCHierarchyFormFields } from 'models/IFMCHierarchyLevelFormFields';

export interface UsersState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  searchedUsers: Array<IUser>;
  allRoles: Array<IRole>;
  userRoles: Array<IUserRole>;
  userScopeHierarchy: UserScopeHierarchy;
  userScopeInitialState: IScopeLevelFormFields;
  availableScopePaths: IScopePath[];
  selectedScopePathNodes: ScopePathNodes;
  scopeNodeNames: IScopeNodeName[];
  userScopeFMCHierarchy: UserScopeFMCHierarchy;
  userScopeFMCHierarchyInitialState: IFMCHierarchyLevelFormFields;
  availableFMCHierarchy: IFMCHierarchy[];
  selectedFMCHierarchyNodes: FMCHierarchyNodes;
  fmcHierarchyNodeNames: IFMCHierarchyNodeName[];
}

export const defaultUsersState: UsersState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  searchedUsers: [],
  allRoles: [],
  userRoles: [],
  userScopeHierarchy: defaultUserScopeHierarchy,
  userScopeInitialState: defaultUserScopeFormFields,
  selectedScopePathNodes: new ScopePathNodes(),
  availableScopePaths: [],
  scopeNodeNames: [],
  userScopeFMCHierarchy: defaultUserScopeFMCHierarchy,
  userScopeFMCHierarchyInitialState: defaultUserScopeFMCHierarchyFormFields,
  availableFMCHierarchy: [],
  selectedFMCHierarchyNodes: new FMCHierarchyNodes(),
  fmcHierarchyNodeNames: [],
};
