import { combineReducers } from 'redux';
import { reducer as reducerForm } from 'redux-form';
import { AppAction } from '../models/action';
import { AppState } from '../state/app-state';
import { reducer as toastrReducer } from 'react-redux-toastr';
import NetworkConnectionReducer from 'sections/networkConnection/redux/reducer';
import CommonReducer from 'sections/common/redux/reducer';
import HomeReducer from 'sections/home/redux/reducer';
import FinancialObjectiveReducer from 'sections/financialObjective/redux/reducer';
import FormsSearchReducer from 'sections/formsSearch/redux/reducer';
import PreviewFOReducer from 'sections/previewFO/redux/reducer';
import RolesReducer from 'sections/roles/redux/reducer';
import CalculatorReducer from 'sections/calculator/redux/reducer';
import UsersReducer from 'sections/users/redux/reducer';
import EmployeesReducer from 'sections/employees/redux/reducer';
import TMUploadReducer from 'sections/tmUpload/redux/reducer';
import ManageReportReducer from 'sections/manageReport/redux/reducer';

export const reducers = {
  form: reducerForm,
  toastr: toastrReducer,
  networkConnection: NetworkConnectionReducer,
  common: CommonReducer,
  home: HomeReducer,
  financialObjective: FinancialObjectiveReducer,
  formsSearch: FormsSearchReducer,
  previewFO: PreviewFOReducer,
  roles: RolesReducer,
  calculator: CalculatorReducer,
  users: UsersReducer,
  employees: EmployeesReducer,
  tmUpload: TMUploadReducer,
  manageReport: ManageReportReducer
};

const appReducer = combineReducers(reducers);

export const rootReducers: any = (state: AppState, action: AppAction) => {
  return appReducer(state, action);
};
