import { UsersState, defaultUsersState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { actionTypes as financialObjectivesActionTypes } from '../../financialObjective/redux/actions';
import { actionTypes as rolesActionTypes } from '../../roles/redux/actions';
import { IUserRole } from 'models/IRole';
import { defaultUserScopeFormFields, defaultUserScopeHierarchy, defaultUserScopeFMCHierarchy } from 'models/user';
import { IScopeHierarchyResponse, IFMCHierarchyResponse } from 'sections/financialObjective/models.client';
import { getScopeLevel, IScopeNodeName, IScopePath, ScopePathNodes } from 'models/LevelMapping';
import { getFMCHierarchyLevel, IFMCHierarchyNodeName, IFMCHierarchy, FMCHierarchyNodes } from 'models/FMCHierarchyLevelMapping';
import { IUserResponse } from '../models.client';
import { defaultUserScopeFMCHierarchyFormFields } from 'models/IFMCHierarchyLevelFormFields';

export const handlers = {
  [actionTypes.SET_USERS_LOADING_TRUE]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_USERS_STATE]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none
    };
  },
  [actionTypes.RESET_USER_DETAILS_STATE]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      userRoles: [],
      userScopeHierarchy: defaultUserScopeHierarchy,
      userScopeInitialState: defaultUserScopeFormFields,
      userScopeFMCHierarchyInitialState: defaultUserScopeFMCHierarchyFormFields,
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_SUCCESS]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.userList,
      searchedUsers: payload
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.userList,
      searchedUsers: payload.response
    };
  },
  [rolesActionTypes.GET_ALL_ROLES_SUCCESS]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      allRoles: payload
    };
  },
  [rolesActionTypes.GET_ALL_ROLES_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      allRoles: payload.response
    };
  },
  [actionTypes.GET_USER_BY_ID_SUCCESS]: (state: UsersState, payload: IUserResponse): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      userRoles: payload.roles,
      selectedScopePathNodes: new ScopePathNodes({
        subSegments: payload.scope?.subSegment ? [payload.scope.subSegment] : [],
        clients: payload.scope?.clients ?? [],
        countries: payload.scope?.countries ?? [],
        divisions: payload.scope?.divisions ?? [],
        subDivisions: payload.scope?.subDivisions ?? [],
        teams: payload.scope?.teams ?? [],
      }),
      userScopeInitialState: {
        clients: payload.scope?.clients ?? [],
        countries: payload.scope?.countries ?? [],
        divisions: payload.scope?.divisions ?? [],
        vicePres: [],
        subDivisions: payload.scope?.subDivisions ?? [],
        subSegment: payload.scope?.subSegment ?[payload.scope?.subSegment] :[],
        teams: payload.scope?.teams ?? [],
      },
      selectedFMCHierarchyNodes: new FMCHierarchyNodes({
        businessSegment: payload.scopeFMC?.businessSegment ? [payload.scopeFMC?.businessSegment] : [],
        globalRegion: payload.scopeFMC?.globalRegion ?? [],
        region: payload.scopeFMC?.region ?? [],
        country: payload.scopeFMC?.country ?? [],
        managingOffice: payload.scopeFMC?.managingOffice ?? [],
        client: payload.scopeFMC?.client ?? [],
        location: payload.scopeFMC?.location ?? [],
        lineOfBusiness: payload.scopeFMC?.lineOfBusiness ?? [],
        functionalUnit: payload.scopeFMC?.functionalUnit ?? [],
      }),
      userScopeFMCHierarchyInitialState:{
        businessSegment: payload.scopeFMC?.businessSegment?? '',
        globalRegion: payload.scopeFMC?.globalRegion ?? [],
        region: payload.scopeFMC?.region ?? [],
        country: payload.scopeFMC?.country ?? [],
        managingOffice: payload.scopeFMC?.managingOffice ?? [],
        client: payload.scopeFMC?.client ?? [],
        location: payload.scopeFMC?.location ?? [],
        lineOfBusiness: payload.scopeFMC?.lineOfBusiness ?? [],
        functionalUnit: payload.scopeFMC?.functionalUnit ?? [],
      }
    };
  },
  [actionTypes.GET_USER_BY_ID_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      userRoles: [],
      userScopeHierarchy: defaultUserScopeHierarchy,
      userScopeFMCHierarchy:defaultUserScopeFMCHierarchy,
    };
  },
  [actionTypes.ADD_ROLE_FOR_USER_SUCCESS]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      userRoles: [...state.userRoles, payload.response]
    };
  },
  [actionTypes.ADD_ROLE_FOR_USER_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.REMOVE_ROLE_FOR_USER_SUCCESS]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      userRoles: [...state.userRoles.filter((role: IUserRole) => role.id !== payload.response.id)]
    };
  },
  [actionTypes.REMOVE_ROLE_FOR_USER_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [financialObjectivesActionTypes.GET_SCOPE_HIERARCHY_SUCCESS]: (state: UsersState, payload: IScopeHierarchyResponse): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      availableScopePaths: payload.scopeHierarchy.map((p) => ({
        subSegment: p.subSegmentCode,
        client: p.clientCode,
        country: p.countryCode,
        division: p.divisionCode,
        subDivision: p.subDivisionCode,
        team: p.teamCode,
      } as IScopePath)),
      scopeNodeNames: payload.codeNames.map(c => ({ code: c.code, name: c.name, level: getScopeLevel(c.level) } as IScopeNodeName)),
    };
  },
  [financialObjectivesActionTypes.GET_SCOPE_HIERARCHY_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      availableScopePaths: [],
    };
  },
  [actionTypes.UPDATE_USER_SCOPE_SUCCESS]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      userScopeHierarchy: payload.response,
      userScopeInitialState: {
        clients: payload.response.clients,
        countries: payload.response.countries,
        divisions: payload.response.divisions,
        vicePres: [],
        subDivisions: payload.response.subDivisions,
        subSegment: payload.response.subSegment,
        teams: payload.response.teams,
      }
    };
  },
  [actionTypes.UPDATE_USER_SCOPE_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [financialObjectivesActionTypes.GET_FMC_HIERARCHY_SUCCESS]: (state: UsersState, payload: IFMCHierarchyResponse): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      availableFMCHierarchy: payload.fmcHierarchy.map((p) => ({
        businessSegment: p.businessSegmentCode,
        globalRegion: p.globalRegionCode,
        region: p.regionCode,
        country: p.countryCode,
        managingOffice: p.managingOfficeCode,
        client: p.clientCode,
        location: p.locationCode,
        lineOfBusiness: p.lineOfBusinessCode,
        functionalUnit: p.functionalUnitCode
      } as IFMCHierarchy)),
      fmcHierarchyNodeNames: payload.codeNames.map(c => ({ code: c.code, name: c.name, level: getFMCHierarchyLevel(c.level) } as IFMCHierarchyNodeName)),
    };
  },
  [financialObjectivesActionTypes.GET_FMC_HIERARCHY_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      availableFMCHierarchy: [],
    };
  },
  [actionTypes.UPDATE_USER_SCOPE_FMCHIERARCHY_SUCCESS]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      userScopeFMCHierarchy: payload.response,
      userScopeFMCHierarchyInitialState: {
        businessSegment: payload.response.businessSegment,
        globalRegion: payload.response.globalRegion,
        region: payload.response.region,
        country: payload.response.country,
        managingOffice: payload.response.managingOffice,
        client: payload.response.client,
        location: payload.response.location,
        lineOfBusiness: payload.response.lineOfBusiness,
        functionalUnit: payload.response.functionalUnit,
      }
    };
  },
  [actionTypes.UPDATE_USER_SCOPE_FMCHIERARCHY_ERROR]: (state: UsersState, payload: any): UsersState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
};

const UsersReducer = (state: UsersState = { ...defaultUsersState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default UsersReducer;
