export const actionTypes = {
  SET_USERS_LOADING_TRUE: 'SET_USERS_LOADING_TRUE',
  RESET_USERS_STATE: 'RESET_USERS_STATE',
  RESET_USER_DETAILS_STATE: 'RESET_USER_DETAILS_STATE',
  GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
  GET_USER_BY_ID_ERROR: 'GET_USER_BY_ID_ERROR',
  ADD_ROLE_FOR_USER_SUCCESS: 'ADD_ROLE_FOR_USER_SUCCESS',
  ADD_ROLE_FOR_USER_ERROR: 'ADD_ROLE_FOR_USER_ERROR',
  REMOVE_ROLE_FOR_USER_SUCCESS: 'REMOVE_ROLE_FOR_USER_SUCCESS',
  REMOVE_ROLE_FOR_USER_ERROR: 'REMOVE_ROLE_FOR_USER_ERROR',
  UPDATE_USER_SCOPE_SUCCESS: 'UPDATE_USER_SCOPE_SUCCESS',
  UPDATE_USER_SCOPE_ERROR: 'UPDATE_USER_SCOPE_ERROR',
  UPDATE_USER_SCOPE_FMCHIERARCHY_SUCCESS: 'UPDATE_USER_SCOPE_FMCHIERARCHY_SUCCESS',
  UPDATE_USER_SCOPE_FMCHIERARCHY_ERROR: 'UPDATE_USER_SCOPE_FMCHIERARCHY_ERROR'
};
