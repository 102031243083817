import { defaultTMUploadState, TMUploadState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';

export const handlers = {
  [actionTypes.SET_TMUPLOAD_LOADING_TRUE]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_TMUPLOAD_STATE]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      errors: []
    };
  },
  [actionTypes.BONUS_CALCULATION_SIMULATION_SUCCESS]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      errors: []
    };
  },
  [actionTypes.BONUS_CALCULATION_SIMULATION_ERROR]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event,
      errors: payload.errors
    };
  },
  [actionTypes.GET_HISTORY_SUCCESS]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      tmUploadItems: payload.items
    };
  },
  [actionTypes.GET_HISTORY_ERROR]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.DOWNLOAD_SUCCESS]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none
    };
  },
  [actionTypes.DOWNLOAD_ERROR]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.RUN_BONUS_CALCULATION_SUCCESS]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event
    };
  },
  [actionTypes.RUN_BONUS_CALCULATION_ERROR]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.RUN_BONUS_CALCULATION_RATING_SUCCESS]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event
    };
  },
  [actionTypes.RUN_BONUS_CALCULATION_RATING_ERROR]: (state: TMUploadState, payload: any): TMUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  }
};

const TMUploadReducer = (state: TMUploadState = { ...defaultTMUploadState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default TMUploadReducer;
