import { defaultEmployeesState, EmployeesState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { actionTypes as financialObjectivesActionTypes } from '../../financialObjective/redux/actions';
import { defaultEmployee, defaultEmployeeFormFields, IEmployee, IEmployeeFormFields, IManager } from 'models/Employee';
import { cloneDeep } from 'lodash';
import { formatUser } from '../employeeHelper';

const resetManagers = (lineManager: IManager) => {
  const searchedManagers = [];

  if (lineManager) {
    searchedManagers.push({
      id: lineManager?.id,
      empId: '',
      firstName: lineManager?.firstName,
      lastName: lineManager?.lastName,
      email: lineManager?.email,
      title: lineManager?.title,
      managerName: '',
      managerTitle: '',
      managerEmailId: ''
    });
  }

  return searchedManagers;
};

const formatFormFieldsData = (newData: IEmployee) => {
  const data: IEmployeeFormFields = {
    employeeId: newData.employeeId,
    accountName: newData.accountName,
    firstName: newData.firstName,
    lastName: newData.lastName,
    email: newData.email,
    title: newData.title,
    country: newData.country?.id,
    currency: newData.country?.currency,
    region: newData.country?.region.name,
    lineManager: {
      id: newData.lineManager?.id,
      empId: '',
      firstName: newData.lineManager?.firstName,
      lastName: newData.lineManager?.lastName,
      email: newData.lineManager?.email,
      title: newData.lineManager?.title,
      managerName: '',
      managerTitle: '',
      managerEmailId: '',
    },
    isEligibleForBonus: newData.isEligibleForBonus,
    reasonOfModification: newData.reasonOfModification
  };

  return data;
};

export const handlers = {
  [actionTypes.SET_EMPLOYEES_LOADING_TRUE]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_EMPLOYEES_STATE]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none
    };
  },
  [actionTypes.RESET_EMPLOYEE_DETAILS_STATE]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      employee: defaultEmployee,
      errors: []
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_SUCCESS]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.userList,
      searchedUsers: payload
    };
  },
  [financialObjectivesActionTypes.SEARCH_USERS_ERROR]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.userList,
      searchedUsers: payload.response
    };
  },
  [actionTypes.GET_EMPLOYEE_SUCCESS]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.getData,
      employee: payload,
      employeeFormFields: formatFormFieldsData(payload),
      searchedManagers: [payload.lineManager],
      errors: []
    };
  },
  [actionTypes.GET_EMPLOYEE_ERROR]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.getData,
      employee: defaultEmployee
    };
  },
  [actionTypes.GET_COUNTRIES_SUCCESS]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.getData,
      countries: payload
    };
  },
  [actionTypes.GET_COUNTRIES_ERROR]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.getData,
      countries: []
    };
  },
  [actionTypes.SET_NEW_EMPLOYEE_FORM]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      employee: defaultEmployee,
      employeeFormFields: defaultEmployeeFormFields,
      errors: []
    };
  },
  [actionTypes.RESET_LINE_MANAGER_STATE]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      searchedManagers: resetManagers(state.employee.lineManager)
    };
  },
  [actionTypes.RESET_SEARCHED_USER_STATE]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      searchedUsers: [formatUser(state.employee)]
    };
  },
  [actionTypes.SEARCH_MANAGERS_SUCCESS]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.userList,
      searchedManagers: payload
    };
  },
  [actionTypes.SEARCH_MANAGERS_ERROR]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.userList,
      searchedManagers: payload.response
    };
  },
  [actionTypes.ADD_OR_UPDATE_EMPLOYEE_SUCCESS]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      employee: payload.response,
      employeeFormFields: formatFormFieldsData(payload.response),
      searchedManagers: [payload.response.lineManager],
      errors: []
    };
  },
  [actionTypes.ADD_OR_UPDATE_EMPLOYEE_ERROR]: (state: EmployeesState, payload: any): EmployeesState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event,
      errors: payload.error
    };
  }
};

const EmployeesReducer = (state: EmployeesState = { ...defaultEmployeesState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default EmployeesReducer;
