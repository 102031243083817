import { IError } from 'models/error';
import { EventType } from 'models/EventType';
import { Notify } from 'models/Notify';
import { ITMUploadItem } from 'models/tmUpload';

export interface TMUploadState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  errors: Array<IError>;
  tmUploadItems: Array<ITMUploadItem>;
}

export const defaultTMUploadState: TMUploadState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  errors: [],
  tmUploadItems: []
};
