import { createToastsMiddleware } from "middleware/toasts";
import { createStore, compose, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { rootReducers } from "./reducers";

export const initializeStore = () => {
  /* eslint-disable no-underscore-dangle */
  const windowIfDefined = typeof window === "undefined" ? null : (window as any);

  // pick debug or dummy enhancer
  const composeEnhancers =
  windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const commandMiddleware = createToastsMiddleware();

  const middleware =
  process.env.ENV === "DEV"
    ? composeEnhancers(applyMiddleware(commandMiddleware, logger, thunk))
    : composeEnhancers(applyMiddleware(commandMiddleware, thunk));

  /* eslint-enable */
  const store = createStore(rootReducers, middleware);
  
  return store;
};