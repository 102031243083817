import * as React from 'react';
import { SidebarStyles } from 'react-sidebar';
import Header from '../components/header';
import AppBody from '../components/body';
import './defaultLayout.scss';
import { Loader } from '../components/loader';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { appHistory } from 'app-history';
import routes from 'screens/routes';
import { createRef } from 'react';

interface DefaultLayoutProps extends InjectedIntlProps {
  children: JSX.Element | JSX.Element[];
  isHidden: boolean | false;
  fluid?: boolean;
  permission?: any[];
  sideMenuDockable?: boolean | true;
  page?: string | false;
}

interface DefaultLayoutState {
  sidebarOpen: boolean;
  notificationDropDownOpen: boolean;
  notificationDropDownVisible: boolean;
  isNavOpen: boolean;
}

const mql = window.matchMedia(`(min-width: 992px)`);

const overlayStyles: SidebarStyles = {
  overlay: {
    zIndex: '2'
  },
  sidebar: {
    zIndex: '3',
    display: 'flex',
    overflow: 'visible'
  }
};

export class DefaultLayoutComponent extends React.PureComponent<
  DefaultLayoutProps,
  DefaultLayoutState,
  InjectedIntlProps
> {
  public constructor(props: DefaultLayoutProps) {
    super(props);
    this.state = {
      sidebarOpen: false,
      notificationDropDownOpen: false,
      notificationDropDownVisible: mql.matches,
      isNavOpen: false
    };
  }

  public static defaultProps = {
    isHidden: false
  };

  private toggleSideMenu = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    });
  };

  private toggleNotificationDropDown = () => {
    this.setState({
      notificationDropDownOpen: !this.state.notificationDropDownOpen
    });
  };

  private onSetSidebarOpen = (open: any) => {
    this.setState({ sidebarOpen: open });
  };

  private mediaQueryChanged = () => {
    this.setState({
      notificationDropDownVisible: mql.matches,
      notificationDropDownOpen: false
    });
  };

  public componentDidMount(): void {
    document.title = this.props.page || 'Scorecard';
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  navRef = createRef<any>();

  private handleClickOutside = (event: any) => {
    if (this.navRef.current && !this.navRef.current?.contains(event.target)) {
      this.setState({ isNavOpen: false });
    }
  };

  private toggleNavBar = () => {
    this.setState({
      isNavOpen: !this.state.isNavOpen
    });
  };

  public render() {
    const attr: { [k: string]: any } = {};
    if (this.props.page) attr.className = `page-${this.props.page} wrapper`;
    return (
      <div {...attr}>
        <div id="content" className={'w-100 m-0'}>
          <Header
            toggleMenu={() => this.toggleSideMenu()}
            sideMenuDocked={false}
            toggleNotification={() => this.toggleNotificationDropDown()}
            showNotificationDropDown={this.state.notificationDropDownVisible}
            isNotificationOpen={this.state.notificationDropDownOpen}
            intl={this.props.intl}
            navRef={this.navRef}
            isNavOpen={this.state.isNavOpen}
            toggleNavBar={() => this.toggleNavBar()}
          />
          <React.Suspense fallback={<Loader />}>
            <div
              className={
                this.props.fluid ? 'default-layout-wrapper default-layout-wrapper--fluid' : 'default-layout-wrapper'
              }
            >
              <AppBody children={this.props.children} intl={this.props.intl}></AppBody>
            </div>
          </React.Suspense>
        </div>
      </div>
    );
  }
}

export const DefaultLayout = injectIntl(DefaultLayoutComponent);
