import {
  FinancialObjectiveState,
  defaultFinancialObjectiveState,
  defaultFinancialObjectiveFormFields,
  defaultFO,
  defaultNonFinancialObjectives
} from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { BonusScheme, IFinancialObjectiveFormFields, ScopeType } from 'models/IFinancialObjective';
import { cloneDeep } from 'lodash';
import { getScopeLevel, IScopeNodeName, IScopePath } from 'models/LevelMapping';
import { formatFinancialFigure,formatDecimalNumber } from 'helpers/form-helper';
import { defaultFinancialFigures } from 'models/IScoreCardFinancialFigures';
import { mask } from 'helpers/helper';
import { IScopeHierarchyResponse } from '../models.client';
import { IFinancialObjectiveResponse, INonFinancialObjectiveResponse } from 'models/models.client';
import { FileModel } from 'models/fileModel';

const formatFormFieldsData = (currentState: FinancialObjectiveState, newData: IFinancialObjectiveResponse) => {
  const state = cloneDeep(currentState);
  const data: IFinancialObjectiveFormFields = {
    user: { ...newData.user, id: Number(newData.user.id) },
    startDate: newData.startDate,
    endDate: newData.endDate,
    status: newData.status,
    subSegment: newData.scope.subSegments,
    divisions: newData.scope.divisions,
    vicePres: newData.scope.vicePres,
    subDivisions: newData.scope.subDivisions,
    teams: newData.scope.teams,
    countries: newData.scope.countries,
    clients: newData.scope.clients,
    multiJurisdiction: newData.financialObjectives?.isManualOverride,
    multiJurisdictionComments: { id: newData?.financialObjectives?.manualOverrideComment?.id ?? '' },
    scope: newData.scope.scopeType as ScopeType,
    comments: newData.scope.scopeComment,
    financialFigures: {
      ...newData.financialObjectives,
      level1Figure: ((newData.bonusSchemeId == BonusScheme.Finance_Controllership || newData.bonusSchemeId == BonusScheme.Finance_FP_A) && !newData.financialObjectives.canReadLevel2Figure)
        ? mask : formatFinancialFigure(newData.financialObjectives.level1Figure),
      level2Figure: newData.financialObjectives.canReadLevel2Figure
        ? formatFinancialFigure(newData.financialObjectives.level2Figure)
        : mask,
      revenueFigure: formatFinancialFigure(newData.financialObjectives.revenueFigure),
      tradeWorkingCapitalARFigure: formatDecimalNumber(newData.financialObjectives.tradeWorkingCapitalARFigure).toString(),
      tradeWorkingCapitalUBRFigure: formatDecimalNumber(newData.financialObjectives.tradeWorkingCapitalUBRFigure).toString()       
    },
    reasonForStatusChange: newData.reasonForStatusChange ?? '',
    manualOverrideOtherCommentJustification: newData.financialObjectives.manualOverrideOtherCommentJustification,
    bonusSchemeId: newData.bonusSchemeId,
    nonFinancialObjectives: formatNonFinancialObjectives(newData.nonFinancialObjectives),
    financeDirector: { id: newData?.financialObjectives?.financeDirector?.id ?? '' },
  };

  return data;
};

const formatNonFinancialObjectives = (response: INonFinancialObjectiveResponse) => {
  const attachments: Array<FileModel> = response.attachments?.map((fileResponse) => ({ ...fileResponse, content: [] })) ?? [];
  return response
    ? { ...response, attachmentsToDelete: [], attachments: attachments }
    : defaultNonFinancialObjectives;
}

export const handlers = {
  [actionTypes.SET_FO_LOADING_TRUE]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_FO_STATE]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      users: [],
      overlappedForms: [],
      financialFigures: defaultFinancialFigures,
      financialObjective: defaultFO,
      financialObjectiveFormFields: defaultFinancialObjectiveFormFields,
      userErrors:'',
      availableScopePaths: [],
    };
  },
  [actionTypes.SEARCH_USERS_SUCCESS]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      users: payload
    };
  },
  [actionTypes.SEARCH_USERS_ERROR]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.userList,
      users: payload.response
    };
  },
  [actionTypes.RESET_OVERLAPPED_FO_STATE]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      overlappedForms: []
    };
  },

  [actionTypes.RESET_SCOPEPATHS_STATE]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      financialObjectiveFormFields: {
        ...state.financialObjectiveFormFields,
        bonusSchemeId: payload,
        subSegment: [],
        vicePres: [],
        divisions: [],
        subDivisions: [],
        teams: [],
        countries: [],
        clients: []
      }
    };
  },
  [actionTypes.GET_OVERLAPPED_FORMS_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      overlappedForms: state.financialObjective
        ? payload.filter((overlapFO: any) => overlapFO.id !== state.financialObjective.id)
        : payload
    };
  },
  [actionTypes.GET_OVERLAPPED_FORMS_ERROR]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      overlappedForms: payload.response
    };
  },
  [actionTypes.GET_SCOPE_HIERARCHY_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: IScopeHierarchyResponse
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      availableScopePaths: payload.scopeHierarchy.map(
        (p) =>
        ({
          subSegment: p.subSegmentCode,
          client: p.clientCode,
          country: p.countryCode,
          division: p.divisionCode,
          vicePres: p.vicePresCode,
          subDivision: p.subDivisionCode,
          team: p.teamCode
        } as IScopePath)
      ),
      scopeNodeNames: payload.codeNames.map(
        (c) => ({ code: c.code, name: c.name, level: getScopeLevel(c.level) } as IScopeNodeName)
      )
    };
  },
  [actionTypes.GET_SCOPE_HIERARCHY_ERROR]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      availableScopePaths: []
    };
  },
  [actionTypes.GET_MULTI_JURISDICTION_COMMENTS_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      multiJurisdictionComments: payload
    };
  },
  [actionTypes.GET_MULTI_JURISDICTION_COMMENTS_ERROR]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      multiJurisdictionComments: payload.response
    };
  },
  [actionTypes.GET_FINANCIAL_FIGURES_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      financialFigures: payload
    };
  },
  [actionTypes.GET_FINANCIAL_FIGURES_ERROR]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
      //financialFigures: payload.response
    };
  },
  [actionTypes.GET_BONUSSCHEMES_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      bonusSchemes: payload
    };
  },
  [actionTypes.GET_BONUSSCHEMES_ERROR]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
  [actionTypes.UPDATE_NFO_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event
    };
  },
  [actionTypes.UPDATE_NFO_ERROR]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.POST_FINANCIAL_OBJECTIVE_FORM_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event
    };
  },
  [actionTypes.POST_FINANCIAL_OBJECTIVE_FORM_ERROR]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.GET_FINANCIAL_OBJECTIVE_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      financialObjective: payload,
      financialObjectiveFormFields: formatFormFieldsData(state, payload),
      users: [payload.user],
      financialFigures: {
        ...payload.financialObjectives,
        level1Figure: ((payload.bonusSchemeId == BonusScheme.Finance_Controllership || payload.bonusSchemeId == BonusScheme.Finance_FP_A) && !payload.financialObjectives.canReadLevel2Figure)
          ? mask : formatFinancialFigure(payload.financialObjectives.level1Figure),
        level2Figure: payload.financialObjectives.canReadLevel2Figure
          ? formatFinancialFigure(payload.financialObjectives.level2Figure)
          : mask,
        revenueFigure: formatFinancialFigure(payload.financialObjectives.revenueFigure)
      }
    };
  },
  [actionTypes.GET_FINANCIAL_OBJECTIVE_ERROR]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
  [actionTypes.GET_DEFAULT_FO_DATES_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      defaultDates: payload
    };
  },
  [actionTypes.GET_DEFAULT_FO_DATES_ERROR]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
  [actionTypes.GET_FINANCE_DIRECTORS_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      financeDirectors: payload
    };
  },
  [actionTypes.GET_FINANCE_DIRECTORS_ERROR]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      financeDirectors: payload.response
    };
  },
  [actionTypes.GET_VALIDATE_FMC_HIERARCHY_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      userErrors: '',
    };
  },
  [actionTypes.GET_VALIDATE_FMC_HIERARCHY_ERROR]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      userErrors:"Scorecard can only be created for manager's reportee or in their FMC scope."
    };
  },
  [actionTypes.GET_NFO_GOALS_SUCCESS]: (
    state: FinancialObjectiveState,
    payload: any
  ): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,      
      nfoGoals: payload.nfoGoals
    };
  },
  [actionTypes.GET_NFO_GOALS_ERROR]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
  [actionTypes.GET_TWC_FIGURES_SUCCESS]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,      
      twcFigures: payload.twcFigures
    };
  },
  [actionTypes.GET_TWC_FIGURES_ERROR]: (state: FinancialObjectiveState, payload: any): FinancialObjectiveState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
};


const FinancialObjectiveReducer = (
  state: FinancialObjectiveState = { ...defaultFinancialObjectiveState },
  action: AppAction
) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default FinancialObjectiveReducer;
