export enum ApprovalType {
  Approve = 'Approve',
  Reject = 'Reject'
}

export interface IApproval {
  action: ApprovalType;
  comment: string;
}

export interface IApprovalAudit {
  userFirstName: string;
  userLastName: string;
  approvalAction: ApprovalType;
  comment: string;
  createdDate: string;
}

export enum ApprovalStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  PendingApproval = 'PendingApproval'
}
