import { CommonState, defaultCommonState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { User } from 'models/user';

export const handlers = {
  [actionTypes.GET_USER_PROFILE_SUCCESS]: (state: CommonState, payload: User): CommonState => {
    return {
      ...state,
      loading: false,
      success: true,
      userProfile: payload
    };
  },
  [actionTypes.GET_USER_PROFILE_ERROR]: (state: CommonState, payload: CommonState): CommonState => {
    return {
      ...state,
      loading: false,
      success: false
    };
  },
  [actionTypes.GET_PRIVILEGES_SUCCESS]: (state: CommonState, payload: any): CommonState => {
    return {
      ...state,
      loading: false,
      success: true,
      allPrivileges: payload
    };
  },
  [actionTypes.GET_PRIVILEGES_ERROR]: (state: CommonState, payload: CommonState): CommonState => {
    return {
      ...state,
      loading: false,
      success: false
    };
  },
  [actionTypes.GET_FINANCIAL_YEARS_SUCCESS]: (state: CommonState, payload: any): CommonState => {
    return {
      ...state,
      loading: false,
      success: true,
      allFinancialYears: payload
    };
  },
  [actionTypes.GET_FINANCIAL_YEARS_ERROR]: (state: CommonState, payload: CommonState): CommonState => {
    return {
      ...state,
      loading: false,
      success: false
    };
  },
  [actionTypes.SET_FINANCIAL_YEAR]: (state: CommonState, payload: number): CommonState => {
    return {
      ...state,
      loading: false,
      success: true,
      selectedFinancialYear: payload
    };
  }
};

const CommonReducer = (state: CommonState = { ...defaultCommonState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default CommonReducer;
