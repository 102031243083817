import { memoize } from 'lodash';
import { mask } from './helper';

export function fieldValidationRequired(value: any, message: any) {
  if (typeof value === 'number') {
    value = value.toString();
  } else {
    value = value ? value.toString() : '';
  }

  if (!value || (typeof value.trim === 'function' && value.trim() === '') || (Array.isArray(value) && !value.length)) {
    return message;
  }
}

export const Validate = {
  required: memoize((message) => (value: any) => fieldValidationRequired(value, message))
};

export const onErrorScrollToField = (errors: any) => {
  if (errors !== undefined) {
    const errElm = document.getElementsByName(Object.keys(errors)[0])[0];
    if (errElm) {
      errElm.focus();
    }
  }
};

export const disableForm = (form: any, state = false): void => {
  if (form?.length) {
    for (let i = 0; i < form.length; i++) {
      const formElement = form.elements[i];
      formElement.disabled = state;
    }
  }
};

export const onErrorScrollToFormField = (errors: any) => {
  if (errors !== undefined) {
    const errElms = document.getElementById(Object.keys(errors)[0]);
    if (errElms) {
      errElms.focus();
    }
  }
};

export const formatFinancialFigure = (figure: string | number) => {
  if (!figure) return '0';
  if (figure === '-') return figure;
  if (figure === mask) return figure;

  const figureString = figure.toString();
  const isNegative = figureString.length > 0 && figureString[0] === '-';
  let formattedFigure = isNegative ? figureString.substring(1) : figureString;
  formattedFigure = figureString.replace(/[^0-9]+/g, '');
  formattedFigure = formattedFigure ? Number(formattedFigure).toLocaleString('en') : '';

  return isNegative ? '-' + formattedFigure : formattedFigure;
};

export const formatNumber = (num: any) => {
  if (num === '-') return 0;

  if (num) {
    return Number(num.toString().replace(/[^0-9-]+/g, ''));
  }
  return 0;
};

export const formatDecimalCurrency = (figure: string | number) => {
  if (!figure) return '0';
  if (figure === '-') return figure;
  if (figure === mask) return figure;

  const figureString = figure.toString();
  const isNegative = figureString.length > 0 && figureString[0] === '-';
  let formattedFigure = isNegative ? figureString.substring(1) : figureString;
  formattedFigure = figureString.replace(/[^0-9.]+/g, '');
  formattedFigure =
    formattedFigure &&
    Number(formattedFigure)
      ?.toLocaleString('en')
      ?.toLowerCase() != 'nan'
      ? Number(formattedFigure).toLocaleString('en')
      : '';

  return isNegative ? '-' + formattedFigure : formattedFigure;
};

export const formatDecimalNumber = (num: any) => {
  if (num === '-') return 0;

  if (num) {
    return Number(num.toString().replace(/[^0-9-.]+/g, ''));
  }
  return 0;
};

export const formatToMonthName = (month: number) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(month - 1); 
  return date.toLocaleString('en-US', { month: 'short' })
};

